import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import SocialsList from 'shared/SocialsList/SocialsList';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet-async';
import { _getImgRd, _getPersonNameRd, _getTitleRd } from 'contains/fakeData';
import Tag from 'shared/Tag/Tag';
import Blogimage from '../../images/pharmacy_image/Featureimage/pexels-artem-podrez-4492048.jpg';

const BlogSingle = () => {
    const renderHeader = () => {
        return (
            <>
                <header className="container rounded-xl">
                    <div className="mx-auto space-y-5">
                        <h1
                            className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl   "
                            title="Quiet ingenuity: 120,000 lunches and counting"
                        >
                            Embracing Wellness: Your Guide to a Healthier Lifestyle with Rite Away Pharmacy
                        </h1>
                        <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                            Welcome to the Rite Away Pharmacy blog, where your health and well-being are our top priorities. In today's
                            fast-paced world, maintaining a healthy lifestyle is crucial, and at Rite Away Pharmacy, we're dedicated to
                            supporting you on this journey. Join us as we explore key insights and tips to unlock a healthier you
                        </span>
                    </div>
                </header>
            </>
        );
    };

    const renderContent = () => {
        return (
            <div id="single-entry-content" className="prose prose-sm sm:prose lg:prose-lg dark:prose-invert" style={{ maxWidth: '100%' }}>
                <h3>1) Normal Delivery Protocols for Out of State Licenses</h3>
                <p>
                    a) If product is of immediate need ship priority overnight with Courier
                    <br />
                    b) If the product needs refrigeration, include Freeze Packaging with it and package accordingly with protection.
                    <br />
                    c) Product pickup times are usually at 3:00 pm for next day delivery and if there is urgent need can be dropped at
                    Interpark Blvd FEDEX station until 9:00 pm
                    <br />
                    d) Include the 800 number on label and notice for customers to reach out if any questions
                </p>

                <h3>2) Procedure to follow if medication is not available or if delivery will be delayed beyond normal delivery time</h3>
                <p>
                    a) Notify clients immediately if medication is not available to determine specific options to either transfer to another
                    pharmacy of choice or proceed to scheduled for a later delivery.
                    <br />
                    b) Notify clients if there would be a delay in delivery to determine client options and transfer to a pharmacy of choice
                    or rescheduled accordingly to patient needs.
                </p>

                <h3>3) Procedure for an Acute Illness</h3>
                <p>
                    a) Follow immediate need protocol and ship priority overnight, communicate with client to determine their preference and
                    if need so transfer the script to a local pharmacy of choice
                </p>

                <h3>4) If Patient Medication is not received</h3>
                <p>
                    a) Verified with the Courier if there is a specific issue to deliver timely
                    <br />
                    b) Create a case if necessary with Courier
                    <br />
                    c) Document if LOST within 24 hrs, proceed to refill and deliver next day Priority overnight.
                    <br />
                    d) If medication is of urgent need proceed to ship replacement and recall existing medication with the courier.
                </p>

                <h3>5) Procedure to ensure proper medication storage conditions</h3>
                <p>
                    a) Include Cold Packaging for medication that requires temperature stability during delivery and reduction of exposure
                    to high temperatures.
                    <br />
                    b) Include Refrigerate signs on the packaging to indicate medication requires refrigeration once received.
                    <br />
                    c) Include a Temperature Monitor device if medication is thermal labile.
                </p>
            </div>
        );
    };

    return (
        <div className="nc-PageSingle">
            <Helmet>
                <title>Delivery policy </title>
            </Helmet>
            <div className="page-title flex items-center justify-center  mb-20" style={{ backgroundColor: '#d6cdea' }}>
                <div className="content-box clearfix">
                    <div>
                        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-2">Delivery Policy</h2>
                    </div>
                    <ul className="bread-crumb clearfix  flex items-center justify-center">
                        <li>
                            <a href="/">Home</a>
                        </li>

                        <li> Delivery Policy</li>
                    </ul>
                </div>
            </div>

            <div className="nc-SingleContent mx-20 space-y-10 mb-20">{renderContent()}</div>
        </div>
    );
};

export default BlogSingle;
