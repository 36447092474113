import React, { FC } from "react";
import Heading from "../Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
import img1 from "../../images/pharmacy_image/Free_shipping/Refill.png";
import img2 from "../../images/pharmacy_image/Free_shipping/Status.png";
import Hometest from "../../images/pharmacy_image/Free_shipping/labtest.png";
import Talk from "../../images/pharmacy_image/Free_shipping/talk.png";
import Chat from "../../images/pharmacy_image/Free_shipping/Chat.png";
import Flueshot from "../../images/pharmacy_image/Free_shipping/flueshot.png";
import allergyextract from "../../images/pharmacy_image/Free_shipping/allergyextract.png";

import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";
export interface SectionHowItWorkProps {
  className?: string;
  data?: (typeof DEMO_DATA)[0][];
}

const DEMO_DATA = [
  {
    id: 1,
    img: Hometest,
    imgDark: Hometest,
    title: "Lab & Home Test",
    desc: "",
  },

  {
    id: 2,
    img: Chat,
    imgDark: Chat,
    title: "Pharmacy Chat",
    desc: "",
  },
  {
    id: 3,
    img: Talk,
    imgDark: Talk,
    title: "1:1 Consultation",
    desc: "",
  },
  {
    id: 4,
    img: Flueshot,
    imgDark: Flueshot,
    title: "Vaccines",
    desc: "",
  },
  {
    id: 5,
    img: allergyextract,
    imgDark: allergyextract,
    title: "Allergy Extracts",
    desc: "",
  },
];

const FreeShipping: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div>
      <Heading
        className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50 nc-p-r-container "
        desc=""
      >
        Services Tailored To You
      </Heading>

      <div>
        <div
          className={`nc-SectionHowItWork ${className}`}
          data-nc-id="SectionHowItWork"
        >
          <div className="relative grid sm:grid-cols-2 lg:grid-cols-5 gap-10 sm:gap-16 xl:gap-20">
            {data.map((item: (typeof DEMO_DATA)[number], index: number) => (
              <div
                key={item.id}
                className="relative flex flex-col items-center max-w-xs mx-auto"
              >
                <NcImage
                  containerClassName="mb-4 sm:mb-5 max-w-[70px] mx-auto"
                  className="rounded-0xl"
                  src={item.img}
                />
                <div className="text-center  space-y-5">
                  {/* <Badge
                                        name={`Step ${index + 1}`}
                                        color={!index ? 'red' : index === 1 ? 'indigo' : index === 2 ? 'yellow' : 'purple'}
                                    /> */}
                  <h3 className="text-base font-semibold">{item.title}</h3>
                  {/* <span className="block text-slate-600 dark:text-slate-400 text-sm leading-6">{item.desc}</span> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeShipping;
