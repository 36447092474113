// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import queryString from "query-string";
import { Product, Productsingle } from "../../redux/store/products/class";
// ** API Imports
import axios from "../../service/axios";

export const getNavProduct = createAsyncThunk(
  "SliderProductData/getNavProduct",
  async (data: {}) => {
    // const query = queryString.stringify(data);
    try {
      let response = await axios.get("/product?productSubCategoryId=" + data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);

export const NavProductSlice = createSlice({
  name: "SliderProductData",
  initialState: {
    navproducts: [] as Product[],
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getNavProduct.fulfilled, (state, action) => {
      state.navproducts = action.payload.productMaster;
    });
  },
});

export default NavProductSlice.reducer;
