import SectionHero2 from "components/SectionHero/SectionHero2";
// import SectionPromo2 from "components/SectionPromo2";
import SectionPromo3 from "components/SectionPromo3";
import CategoryCard from "../../components/StartCard/CategoryCard";
import FreeShipping from "../../components/StartCard/FreeShipping";
import FindHealthcare from "../../components/StartCard/FindHealthcare";
import SectionStatistic from "containers/PageAbout/SectionStatistic";
import StartCard from "components/StartCard/StartCard";
import ShopWay from "components/StartCard/ShopWay";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      <SectionHero2 />

      {/* <div className="mt-24 lg:mt-32">
                <DiscoverMoreSlider />
            </div> */}
      <div className="container relative space-y-24  lg:space-y-32   my-16">
        <SectionStatistic />
      </div>

      {/* //B2B Card section// */}
      {/* <div className="container relative space-y-24  lg:space-y-32 lg:mt-32 sm:mt-60 mb-32">
        <StartCard />
      </div> */}

      <div style={{ backgroundColor: "#f8fafc" }}>
        {/* --- MoreShopCard -- */}
        {/* <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32 pt-10">
          <MoreShopCard />
        </div> */}
        <div className="container relative space-y-24  lg:space-y-32 my-16 pt-14">
          <CategoryCard />
        </div>
        <div className="container relative space-y-24 lg:space-y-32 pb-14 ">
          <ShopWay />
        </div>
      </div>

      <div className="container relative space-y-24 my-20 lg:space-y-32 lg:my-20">
        {/* SECTION */}
        {/* <SectionSliderProductCard data={products} /> */}

        {/* <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
                    <SectionHowItWork />
                </div> */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}

        {/* SECTION */}
        {/* <div className="relative py-24 lg:py-32">
                    <BackgroundSection />
                    <SectionGridMoreExplore />
                </div> */}

        {/* SECTION */}
        {/* <SectionGridFeatureItems /> */}

        {/*  */}
        {/* <div>
          <SectionPromo2 />
        </div> */}

        {/* //Freesameday// */}
        {/* <FreeSameDayPickup /> */}

        {/* SECTION 3 */}
        {/* <SectionSliderLargeProduct cardStyle="style2" /> */}

        {/*  */}
        {/* <SectionSliderCategories /> */}

        {/* SECTION */}
        <div>
          <SectionPromo3 />
        </div>

        {/* <SectionPromo4 /> */}

        {/* <SectionSliderProductCard heading="Best Sellers" subHeading="NFC Cards" /> */}

        {/* <FindHealthcare /> */}
        {/* 
        <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <div>
            <Heading rightDescText="From the Ciseco blog">
              The latest news
            </Heading>
            <SectionMagazine5 />
            <div className="flex mt-16 justify-center">
              <ButtonSecondary>Show all blog articles</ButtonSecondary>
            </div>
          </div>
        </div> */}

        {/*  */}
        {/* <SectionClientSay /> */}
      </div>
      <div className="container relative space-y-24 my-20 lg:space-y-32 lg:my-20">
        {" "}
        <FreeShipping />
      </div>
    </div>
  );
}

export default PageHome;
