// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import queryString from "query-string";
import { Product, Productsingle } from "../../../redux/store/products/class";
// ** API Imports
import axios from "../../../service/axios";

export const getSliderProduct = createAsyncThunk(
  "SliderProductData/getSliderProduct",
  async () => {
    // const query = queryString.stringify(data);
    try {
      let response = await axios.get(
        "/product?productCategoryId=be51b0d7-5ea5-45fa-90ff-e6e5205a03bd",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);
export const getBestSellerProduct = createAsyncThunk(
  "SliderProductData/getBestSellerProduct",
  async () => {
    // const query = queryString.stringify(data);
    try {
      let response = await axios.get(
        "/product?productCategoryId=59fc06b1-bee3-4881-bc45-e526023e4940",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);

export const getProductById = createAsyncThunk(
  "getproductAllData/getProductById",
  async (id: string) => {
    // console.log("id", id);
    // const query = queryString.stringify(data);
    try {
      let response = await axios.get(`/product/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);

export const getProductcategory = createAsyncThunk(
  "getproductAllData/getProductcategory",
  async () => {
    try {
      let response = await axios.get("/product-category?option=true", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);

export const productSliderSlice = createSlice({
  name: "SliderProductData",
  initialState: {
    productslider: [] as Product[],
    bestproductslider: [] as Product[],
    productCount: 0 as number,
    nextPage: 1 as number,
    productcategory: [],
    proudctById: {} as Productsingle,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getSliderProduct.fulfilled, (state, action) => {
      state.productslider = action.payload.productMaster;
    });
    builder.addCase(getBestSellerProduct.fulfilled, (state, action) => {
      state.bestproductslider = action.payload.productMaster;
    });
    builder.addCase(getProductcategory.fulfilled, (state, action) => {
      state.productcategory = action.payload;
    });
    builder.addCase(getProductById.fulfilled, (state, action) => {
      state.proudctById = action.payload;
    });
  },
});

export default productSliderSlice.reducer;
