import React, { Suspense, FC, useEffect } from 'react';
import MyRouter from 'routers/index';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AxiosInterceptor } from './service/axios';

const App: React.FC = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Rite-Away Pharmacy</title>
                <meta name="description" content="Rite-Away Pharmacy" />
            </Helmet>
            <Suspense fallback={null}>
                <AxiosInterceptor />
                <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
                    <MyRouter />
                </div>
            </Suspense>
        </HelmetProvider>
    );
};

export default App;
