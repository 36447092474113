import { Helmet } from 'react-helmet-async';

const StatesServiced = () => {
    interface StateData {
        name: string;
        value: string;
    }

    const states: StateData[] = [
        { name: 'State of Arizona', value: 'State of Colorado' },
        { name: 'State of Connecticut', value: 'State of Delaware' },
        { name: 'State of District of Columbia', value: 'State of Florida' },
        { name: 'State of Georgia', value: 'State of Illinois' },
        { name: 'State of Indiana', value: 'State of Kansas' },
        { name: 'State of Maine', value: 'State of Massachusetts' },
        { name: 'State of Minnesota', value: 'State of Missouri' },
        { name: 'State of Montana', value: 'State of New Mexico' },
        { name: 'State of New York', value: 'State of Ohio' },
        { name: 'State of Oklahoma', value: 'State of Pennsylvania' },
        { name: 'State of Rhode Island', value: 'State of Texas' },
        { name: 'State of Utah', value: 'State of Vermont' },
        { name: 'State of Washington', value: 'State of West Virginia' },
        { name: 'State of Wisconsin', value: 'State of Wyoming' },
    ];

    const renderStates = () => {
        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                    <div>
                        <p className="text-lg font-semibold">Jurisdiction</p>
                    </div>
                </div>
                <div className="border-t border-slate-200 dark:border-slate-700 p-1 sm:p-4 divide-y divide-y-slate-200 dark:divide-slate-700">
                    <div className="flex-1">
                        <div>
                            <div className="text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                                {states.map((state, index) => (
                                    <div key={index} className="flex justify-between py-3">
                                        <span className="text-sm text-slate-500 dark:text-slate-400">{state.name}</span>
                                        <span className="text-sm text-slate-500 dark:text-slate-400">{state.value}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="nc-PageSingle">
            <Helmet>
                <title>State policy </title>
            </Helmet>
            <div className="page-title flex items-center justify-center" style={{ backgroundColor: '#d6cdea' }}>
                <div className="content-box clearfix">
                    <div>
                        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">States Serviced</h2>
                    </div>
                    <ul className="bread-crumb clearfix  flex items-center justify-center">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li> States Serviced</li>
                    </ul>
                </div>
            </div>
            <div className="nc-SingleContent container space-y-10 my-10">{renderStates()}</div>
        </div>
    );
};

export default StatesServiced;
