import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Product, PRODUCTS } from "data/data";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";

export interface ProductCardProps {
  className?: string;
  //   data?: Product;
  isLiked?: boolean;
  name: string;
  id: string;
  image: string;
  price: number;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  //   data = PRODUCTS[0],
  name,
  id,
  price,
  image,
  isLiked,
}) => {
  const [variantActive, setVariantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const navigate = useNavigate();
  const clickProductDetail = () => {
    navigate(`/product-details/${id}`);
  };
  const url = "https://dev-api.instaacard.com/";
  const sliderimage = `${url}${image}`;

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
        data-nc-id="ProductCard"
      >
        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
          <NcImage
            style={{ cursor: "pointer" }}
            onClick={clickProductDetail}
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
            src={sliderimage}
            className="object-fill w-full h-full drop-shadow-xl"
          />

          <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" />
        </div>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
          <div>
            <h2
              className={`nc-ProductCard__title text-base font-semibold transition-colors`}
              onClick={clickProductDetail}
            >
              {name}
            </h2>
          </div>

          <div
            className="flex justify-between items-end "
            onClick={clickProductDetail}
          >
            <Prices price={price} />
          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      {/* <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      /> */}
    </>
  );
};

export default ProductCard;
