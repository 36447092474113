// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import queryString from "query-string";
import { Product, Productsingle } from "./class";
// ** API Imports
import axios from "../../../service/axios";

export const getProduct = createAsyncThunk(
  "getproductAllData/getProduct",
  async (data: {}) => {
    // const query = queryString.stringify(data);
    try {
      let response = await axios.get("/product?" + data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);
export const getProductById = createAsyncThunk(
  "getproductAllData/getProductById",
  async (id: string) => {
    // console.log("id", id);
    // const query = queryString.stringify(data);
    try {
      let response = await axios.get(`/product/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);
export const getselectedProduct = createAsyncThunk(
  "getproductAllData/getselectedProduct",
  async (id: string) => {
    // console.log("id", id);
    // const query = queryString.stringify(data);
    try {
      let response = await axios.get(`/product/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);
export const getProductcategory = createAsyncThunk(
  "getproductAllData/getProductcategory",
  async () => {
    try {
      let response = await axios.get("/product-category?option=true", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response?.data;
    } catch (err) {
      return {};
    }
  }
);

// export const getProductsbycategory = createAsyncThunk(
//   "getproductAllData/getProductsbycategory",
//   async (data: {}) => {
//     const query = queryString.stringify(data);

//     try {
//       let response = await axios.get("/product?" + query, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       return response?.data;
//     } catch (err) {
//       return {};
//     }
//   }
// );

export const productSlice = createSlice({
  name: "getproductAllData",
  initialState: {
    product: [] as Product[],
    productCount: 0 as number,
    nextPage: 1 as number,
    selectedData: null,
    productcategory: [],
    proudctById: {} as Productsingle,
  },

  reducers: {
    removeCategory: (state) => {
      state.productcategory = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.product = action.payload.productMaster;
    });
    builder.addCase(getProductcategory.fulfilled, (state, action) => {
      state.productcategory = action.payload;
    });
    builder.addCase(getProductById.fulfilled, (state, action) => {
      state.proudctById = action.payload;
    });
    builder.addCase(getselectedProduct.fulfilled, (state, action) => {
      state.selectedData = action.payload;
      return;
    });
  },
});

export default productSlice.reducer;
export const { removeCategory } = productSlice.actions;
