import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import CountUp from "react-countup";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "260",
    subHeading: "Prescriptions  Serviced",
  },
  {
    id: "2",
    heading: "33",
    subHeading: "Customers Serviced",
  },
  {
    id: "3",
    heading: "12",
    subHeading: "Providers Serviced",
  },
  // {
  //   id: "4",
  //   heading: "220+",
  //   subHeading: "of scripts filled daily",
  // },
];

export interface SectionStatisticProps {
  className?: string;
}
const BG_COLORS = ["bg-red-50", "bg-green-50", "bg-yellow-50"];

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
      // desc=" We’re impartial and independent, and every day we create distinctive,
      //   world-class programmes and content"
      >
        🚀 Fast Facts
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item, index) => (
          <div
            key={item.id}
            className={`p-6 ${BG_COLORS[index]} dark:bg-neutral-800  dark:border-neutral-800 text-center`}
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              <CountUp
                start={0}
                end={parseFloat(item.heading)}
                duration={10}
                delay={0.2}
              />
              K
            </h3>
            <span className="block text-base mt-3 font-semibold">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
