import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import AddressCard from "./AddressCard";

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact </title>
      </Helmet>

      <div className="page-title flex items-center justify-center bg-green-50">
        <div className="content-box clearfix">
          <h1> Location</h1>
          <ul className="bread-crumb clearfix  flex items-center justify-center">
            <li>
              <a href="/">Home</a>
            </li>
            <li> Location</li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="relative my-10 lg:my-20   ">
          <AddressCard />
        </div>
      </div>
    </div>
  );
};

export default PageContact;
