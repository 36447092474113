import React from "react";

const PulseAnimation: React.FC = () => {
  return (
    <div className="relative h-80 flex  border-2 rounded-lg mx-auto mt-1 dark:bg-gray-700 max-w-sm overflow-hidden">
      <div className="absolute top-0 left-6 mt-5">
        <div className="w-12 h-12 bg-gray-300 rounded-full animate-pulse"></div>
      </div>
      <div className="flex animate-pulse flex-row items-center h-full space-x-5 absolute left-6 mt-5">
        {/* Rest of your content */}
        <div className="flex flex-col space-y-3">
          <div className="w-36 bg-gray-300 h-6 rounded-md"></div>
          <div className="w-24 bg-gray-300 h-6 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default PulseAnimation;
