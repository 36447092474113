import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import PageHome from 'containers/PageHome/PageHome';
import Page404 from 'containers/Page404/Page404';
import AccountPage from 'containers/AccountPage/AccountPage';
import PageContact from 'containers/PageContact/PageContact';
import ProviderPage from 'containers/ProviderPage/index';
import PageLocation from 'containers/PageContact/Pagelocation';
import Servicepage from 'containers/ServicePage/index';
import PageAbout from 'containers/PageAbout/PageAbout';
import PageSignUp from 'containers/PageSignUp/PageSignUp';
import PageLogin from 'containers/PageLogin/PageLogin';
import PageSubcription from 'containers/PageSubcription/PageSubcription';
import PdfView from 'containers/PdfFile/hippa';
import deliveryPdf from 'containers/PdfFile/DeliveryPolicy';
import statePdf from 'containers/PdfFile/StateService';
import BlogPage from 'containers/BlogPage/BlogPage';
import BlogSingle from 'containers/BlogPage/BlogSingle';
import Delivery from 'containers/DeliveryPage/delivery';
import SiteHeader from 'containers/SiteHeader';
import PageCollection from 'containers/PageCollection';
import PVCCardCollection from 'containers/PVCcollection';
import PVCCardCategory from '../containers/PvcCategory';
import MetalCollection from 'containers/metalcollection';
import PageSearch from 'containers/PageSearch';
import PageHome2 from 'containers/PageHome/PageHome2';
import PageHome3 from 'containers/PageHome/PageHome3';
import ProductDetailPage from 'containers/ProductDetailPage/ProductDetailPage';
import SingleProductDetailPage from '../containers/ProductDetailPage/SingleProductdetail';
import ProductDetailPage2 from 'containers/ProductDetailPage/ProductDetailPage2';
import AccountSavelists from 'containers/AccountPage/AccountSavelists';
import AccountPass from 'containers/AccountPage/AccountPass';
import state from 'containers/State/state';
import AccountBilling from 'containers/AccountPage/AccountBilling';
import AccountOrder from 'containers/AccountPage/AccountOrder';
import CartPage from 'containers/ProductDetailPage/CartPage';
import CheckoutPage from 'containers/PageCheckout/CheckoutPage';
import PageCollection2 from 'containers/PageCollection2';
import FilterProductCollection from 'containers/FilterProductCollection';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { verifyToken } from '../redux/authslice';
import { AppDispatch } from '../redux/store';
import authConfig from '../auth/config';
import { isUserLoggedIn } from '../utils/userloggin';

export const pages: Page[] = [
    { path: '/', component: PageHome },
    { path: '/home2', component: PageHome2 },
    { path: '/home3', component: PageHome3 },
    //
    { path: '/home-header-2', component: PageHome },
    { path: '/product-details/:id', component: SingleProductDetailPage },
    { path: '/product-detail', component: ProductDetailPage },
    { path: '/product-detail-2', component: ProductDetailPage2 },
    //
    { path: '/page-collection-2', component: PageCollection2 },
    { path: '/pvc-card/subcategory', component: PVCCardCollection },
    { path: '/products/:id', component: FilterProductCollection },
    { path: '/metal-card/subcatogory', component: MetalCollection },
    { path: '/page-collection', component: PageCollection },
    { path: '/page-search', component: PageSearch },
    //
    { path: '/account', component: AccountPage },
    { path: '/account-savelists', component: AccountSavelists },
    { path: '/account-change-password', component: AccountPass },
    { path: '/account-billing', component: AccountBilling },
    { path: '/account-my-order', component: AccountOrder },
    //
    { path: '/cart', component: CartPage },
    { path: '/checkout', component: CheckoutPage },
    //
    { path: '/blog', component: BlogPage },
    { path: '/blog-single', component: BlogSingle },

    //
    { path: '/contact', component: PageContact },
    { path: '/providers', component: ProviderPage },
    { path: '/service', component: Servicepage },
    { path: '/location', component: PageLocation },
    { path: '/about', component: PageAbout },
    { path: '/signup', component: PageSignUp },
    { path: '/login', component: PageLogin },
    { path: '/subscription', component: PageSubcription },
    { path: '/hippa-service', component: PdfView },
    // { path: '/delivery-service', component: deliveryPdf },
    { path: '/delivery-service', component: Delivery },
    { path: '/states-serviced', component: state },
    // { path: '/states-serviced', component: statePdf },
];

const MyRoutes = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (isUserLoggedIn()) {
            dispatch(verifyToken());
        }
    }, []);

    return (
        <>
            <Toaster />
            <ScrollToTop />
            <SiteHeader />
            <Routes>
                {pages.map(({ component: Component, path }, index) => {
                    // console.log()
                    // if (!isUserLoggedIn() ) {
                    //   return <Route key={index} element={<PageLogin />} path={path} />;
                    // }
                    // console.log(index, "injqnjncfjsdnvdns");
                    return <Route key={index} element={<Component />} path={path} />;
                })}

                <Route element={<Page404 />} path="*" />
            </Routes>
            <Footer />
        </>
    );
};

export default MyRoutes;
