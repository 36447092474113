import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
// import Select from "shared/Select/Select";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import * as yup from 'yup';

import SocialsList from 'shared/SocialsList/SocialsList';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionPromo1 from 'components/SectionPromo1';
import AddressCard from './AddressCard';
import { Form, FormFeedback } from 'reactstrap';
import NcImage from 'shared/NcImage/NcImage';
import serviceimage from '../../images/pharmacy_image/Contact/Logo.png';
import logo from '../../images/logo/rite-away-logo.jpg';
import serviceimage1 from '../../images/pharmacy_image/Featureimage/pexels-artem-podrez-4492048.jpg';
import serviceimage2 from '../../images/pharmacy_image/Featureimage/pexels-artem-podrez-4492069.jpg';
import serviceimage3 from '../../images/pharmacy_image/Featureimage/pexels-ivan-samkov-4989138.jpg';
import serviceimage4 from '../../images/pharmacy_image/Featureimage/pexels-mart-production-7230192.jpg';
import serviceimage5 from '../../images/pharmacy_image/Featureimage/syringe-1884784_1280.webp';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { MapPin } from 'react-feather';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import classNames from 'classnames';
import axios from 'axios';
import { createsendmessage } from 'redux/store/contact';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import service from 'service/constant';
export interface PageContactProps {
    className?: string;
}
export interface CardCategoryData {
    name: string;
    desc: string;
    img: string;
    color?: string;
    addressLine1?: string;
    addressLine2?: string;
    googleLocation?: string;
    phoneNo?: string;
    countryName?: string;
    website?: string;
    email: string;
}

const CATS: CardCategoryData[] = [
    {
        desc: 'Refill prescriptions online',
        img: logo,
        color: 'bg-red-50',
        name: 'Rite Away Pharmacy',
        addressLine1: '2235 THOUSAND OAKS DR STE 102',
        addressLine2: 'SAN ANTONIO, TX 78232',
        website: 'https://riteaway1drxrefill.com/',
        email: 'riteaway1@drxapp.com',
        countryName: 'BEXAR',
        phoneNo: '2104902733',
        googleLocation:
            'https://www.google.com/maps/place/Rite+Away+Pharmacy+%26+Med+Supplies/@29.587707,-98.4553283,16.68z/data=!4m14!1m7!3m6!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!2sRite+Away+Pharmacy+%26+Med+Supplies!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94!3m5!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94?authuser=0&entry=ttu',
    },
    {
        desc: 'Retail Service',
        img: logo,
        color: 'bg-amber-50',
        name: 'Rite Away Specialty Pharmacy',
        addressLine1: '2235 THOUSAND OAKS DR STE 102A',
        addressLine2: 'SAN ANTONIO, TX 78232',
        website: 'https://riteawaysprx.drxrefill.com/',
        email: 'riteawaysprx@drxapp.com',
        countryName: 'BEXAR',
        phoneNo: '8772548507',
        googleLocation:
            'https://www.google.com/maps/place/Rite+Away+Pharmacy+%26+Med+Supplies/@29.587707,-98.4553283,16.68z/data=!4m14!1m7!3m6!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!2sRite+Away+Pharmacy+%26+Med+Supplies!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94!3m5!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94?authuser=0&entry=ttu',
    },
    {
        desc: 'Sterile/Non Sterile Compounding',
        img: logo,
        color: 'bg-sky-50',
        name: 'Rite-Away Pharmacy',
        addressLine1: '2716 SW Military Dr #103',
        addressLine2: 'San Antonio, TX 78224',
        website: 'https://riteaway2.drxrefill.com/',
        email: 'riteaway2@drxapp.com',
        countryName: 'TRAVIS',
        phoneNo: '2109273742',
        googleLocation:
            'https://www.google.com/maps/place/Rite-Away+Pharmacy+%26+Medical+Supply+%23+2/@29.3561578,-98.5426628,16z/data=!4m14!1m7!3m6!1s0x865c59b9c5f35a11:0xd1ee08e1b8943f3e!2sRite-Away+Pharmacy+%26+Medical+Supply+%23+2!8m2!3d29.3561531!4d-98.5400879!16s%2Fg%2F1tg8k48g!3m5!1s0x865c59b9c5f35a11:0xd1ee08e1b8943f3e!8m2!3d29.3561531!4d-98.5400879!16s%2Fg%2F1tg8k48g?authuser=0&entry=ttu',
    },
    {
        desc: 'Long Term Care Facilities',
        img: logo,
        color: 'bg-purple-50',
        name: 'Rite Away Pharmacy 3',
        addressLine1: '730 W Stassney Ln #160',
        addressLine2: 'Austin, TX 78745',
        website: 'https://riteaway3.drxrefill.com/',
        email: 'riteaway3@drxapp.com',
        countryName: 'BEXAR',
        phoneNo: '5128272600',
        googleLocation:
            'https://www.google.com/maps/place/Rite-Away+Pharmacy+%26+Medical+Supply+%233/@30.2094111,-97.7832817,16z/data=!4m14!1m7!3m6!1s0x8644b4ab77674667:0xd9a93f6da62f88b!2sRite-Away+Pharmacy+%26+Medical+Supply+%233!8m2!3d30.2094111!4d-97.7832817!16s%2Fg%2F1hg4yy69j!3m5!1s0x8644b4ab77674667:0xd9a93f6da62f88b!8m2!3d30.2094111!4d-97.7832817!16s%2Fg%2F1hg4yy69j?authuser=0&entry=ttu',
    },
    {
        color: 'bg-orange-50',
        name: 'Rite Away Pharmacy 4',
        addressLine1: '4203 James Casey St suite a',
        addressLine2: 'Austin, TX 78745',
        website: 'https://riteaway4.drxrefill.com/',
        email: 'riteaway4@drxapp.com',
        countryName: 'TRAVIS',
        phoneNo: '5128272250',
        googleLocation:
            'https://www.google.com/maps/place/Rite-Away+Pharmacy+and+Medical+Supply+%234/@30.2252827,-98.9273805,9z/data=!4m10!1m2!2m1!1sRite-Away+Pharmacy+near+austin!3m6!1s0x8644b446ca9d5e79:0xc60f33eeb95deebc!8m2!3d30.2252827!4d-97.773816!15sCh5SaXRlLUF3YXkgUGhhcm1hY3kgbmVhciBhdXN0aW4iA4gBAVogIh5yaXRlIGF3YXkgcGhhcm1hY3kgbmVhciBhdXN0aW6SAQhwaGFybWFjeeABAA!16s%2Fg%2F1s04d3gmr?authuser=0&entry=ttu',
        desc: 'Personalized Service – white glove, synchronization',
        img: logo,
    },
    {
        name: 'Rite Away Pharmacy 6',
        color: 'bg-orange-50',
        addressLine1: '426 Castroville Rd,',
        addressLine2: 'San Antonio, TX 78207',
        website: 'https://riteaway6.drxrefill.com/',
        email: 'riteaway6@drxapp.com',
        countryName: 'TRAVIS',
        phoneNo: '2104339991',
        googleLocation:
            'https://www.google.com/maps/place/Rite-Away+Pharmacy+%26+Medical+Supply/@29.418367,-99.6983528,9z/data=!3m1!5s0x865c593479a1b64f:0xbd577434f2ab1319!4m10!1m2!2m1!1sRite-Away+Pharmacy+near+Castroville+Road,+San+Antonio,+TX!3m6!1s0x865c59347979f7eb:0x1a07841fb7eaf707!8m2!3d29.4183484!4d-98.544873!15sCjlSaXRlLUF3YXkgUGhhcm1hY3kgbmVhciBDYXN0cm92aWxsZSBSb2FkLCBTYW4gQW50b25pbywgVFgiA4gBAVo5IjdyaXRlIGF3YXkgcGhhcm1hY3kgbmVhciBjYXN0cm92aWxsZSByb2FkIHNhbiBhbnRvbmlvIHR4kgEIcGhhcm1hY3ngAQA!16s%2Fg%2F1tm0c6vx?authuser=0&entry=ttu',
        desc: 'Personalized Service – white glove, synchronization',
        img: logo,
    },
    {
        desc: 'Specialty Pharmacy Discount Programs',
        img: serviceimage,
        color: 'bg-pink-100',
        name: 'Liberty Pharmacy',
        addressLine1: '8650 Spicewood Springs Rd #106',
        addressLine2: 'Austin, TX 78759',
        website: 'https://liberty.drxrefill.com/',
        email: 'liberty@drxapp.com',
        countryName: 'TRAVIS',
        phoneNo: '5122497500',
        googleLocation:
            'https://www.google.com/maps/place/Liberty+Pharmacy/@30.4329859,-98.92519,9z/data=!4m10!1m2!2m1!1sliberty+pharmacy!3m6!1s0x8644ccd85ff438f3:0xb2c4cb237d9194db!8m2!3d30.4330058!4d-97.7715715!15sChBsaWJlcnR5IHBoYXJtYWN5WhIiEGxpYmVydHkgcGhhcm1hY3mSAQhwaGFybWFjeeABAA!16s%2Fg%2F1wb8v28c?entry=ttu',
    },
];

const info = [
    {
        title: '🗺 ADDRESS',
        desc: '2235 Thousand Oaks Dr Ste 102 San Antonio, TX 78232',
    },
    // {
    //     title: '💌 EMAIL',
    //     desc: 'nc.example@example.com',
    // },
    {
        title: '📞 PHONE',
        desc: '(210) 490-2733',
    },
    {
        title: '🖨 Fax',
        desc: '(210) 490-2416',
    },
];

const PageContact: FC<PageContactProps> = ({ className = '' }) => {
    const locationoptions = [
        {
            value: 'San Antonio : Rite Away Pharmacy',
            label: 'San Antonio : Rite Away Pharmacy',
        },
        {
            value: 'San Antonio : Rite Away Specialty Pharmacy',
            label: 'San Antonio : Rite Away Specialty Pharmacy',
        },
        {
            value: 'San Antonio : Rite-Away Pharmacy',
            label: 'San Antonio : Rite-Away Pharmacy',
        },
        {
            value: 'San Antonio : Rite Away Pharmacy 6',
            label: 'San Antonio : Rite Away Pharmacy 6',
        },
        {
            value: 'Austin : Rite Away Pharmacy 3',
            label: 'Austin : Rite Away Pharmacy 3',
        },
        {
            value: 'Austin : Rite Away Pharmacy 4',
            label: 'Austin : Rite Away Pharmacy 4',
        },
        {
            value: 'Austin : Liberty Pharmacy',
            label: 'Austin : Liberty Pharmacy',
        },
        // {
        //     value: 'Travis : Liberty Pharmacy',
        //     label: 'Travis : Liberty Pharmacy',
        // },
    ] as any[];

    const validationScheme = yup.object().shape({
        email: yup.string().email('Must be a valid email.').required('Email is required'),
        firstName: yup.string().required('First Name is required.'),
        lastName: yup.string().required('Last Name is required.'),
        mobile: yup
            .number()
            .typeError('Mobile must be in number.')
            .required('Mobile1 is required.')
            .test('len', 'Number must be of 13 digits.', (val) => {
                if (val.toString().length <= 13) {
                    return true;
                } else {
                    return false;
                }
            })
            .nullable(),
        subject: yup.string().required('Subject is required.'),
        location: yup.object().required('Location is required.').typeError('Location must be object.').nullable(),
    });

    interface Location {
        message?: string;
        label?: {
            message?: string;
        };
        value?: {
            message?: string;
        };
    }
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
        resolver: yupResolver(validationScheme),
    });
    const dispatch = useDispatch<AppDispatch>();

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        const groupdata = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            mobile: data?.mobile,
            message: data?.message,
            subject: data?.subject,
            location: data?.location?.value,
        };
        try {
            await axios
                .post(service.API_URL + '/email/send-mail', groupdata, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'hgeItywHnj@#jK',
                    },
                })
                .then((res) => {
                    if (res) {
                        Swal.fire({
                            title: 'Success',
                            text: 'Thank you for your message! Our team has received it and will respond shortly',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            customClass: {
                                confirmButton: 'btn-custom-color',
                            },
                        });
                        reset({
                            subject: '',
                            location: '',
                            message: '',
                            mobile: '',
                            lastName: '',
                            email: '',
                            firstName: '',
                        });
                    }
                })
                .catch((err) => {
                    console.log('error', err);
                    Swal.fire({
                        title: 'Error',
                        text: err.response.data.message || 'Something went wrong',
                        icon: 'error',
                        confirmButtonText: 'Okay',
                        customClass: {
                            confirmButton: 'btn-custom-color',
                        },
                    });
                });
        } catch (error: any) {
            console.error('Error:', error);
            toast.error(error.response?.data?.message || 'Something went wrong!');
        }
    };

    return (
        <div className={`nc-PageContact overflow-hidden ${className}`} data-nc-id="PageContact">
            <Helmet>
                <title>Contact </title>
            </Helmet>

            <div className="page-title flex items-center justify-center" style={{ backgroundColor: '#d6cdea' }}>
                <div className="content-box clearfix">
                    <div>
                        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">Contact</h2>
                    </div>
                    <ul className="bread-crumb clearfix  flex items-center justify-center">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li> Contact</li>
                    </ul>
                </div>
            </div>

            <div className="container relative ">
                {/* <h2 className="my-20   flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Our Branches
                </h2> */}
                <div className="grid lg:grid-cols-3 gap-10 my-20">
                    {CATS.map((item, index) => (
                        <div key={index} className="relative flex flex-col card cardborder">
                            <NcImage
                                containerClassName="aspect-w-10 aspect-h-5   overflow-hidden"
                                src={item.img}
                                className="object-contain py-10   "
                            />

                            <div key={index} className={`flex flex-col p-5  ${item.color}  dark:bg-opacity-90 rounded-lg`}>
                                {/* <div dangerouslySetInnerHTML={{ __html: item.svg }}></div> */}
                                <div className="mt-2">
                                    <p className="font-semibold text-slate-900 ">
                                        <b>{item.name}</b>
                                    </p>
                                    <p className="font-semibold text-slate-900">
                                        <b>Address:</b>
                                        <span>
                                            <p className="text-slate-500 mt-0.5 text-sm">{item.addressLine1}</p>
                                            <p className="text-slate-500 mt-0.5 text-sm">{item.addressLine2}</p>
                                        </span>
                                    </p>
                                    <p>
                                        <b>County : </b>
                                        <span className="text-slate-500 mt-0.5 text-sm">{item.countryName}</span>
                                    </p>
                                    <p>
                                        <b>Phone : </b> <span className="text-slate-500 mt-0.5 text-sm"> {item.phoneNo}</span>
                                    </p>
                                    <p>
                                        <b>Email : </b> <span className="text-slate-500 mt-0.5 text-sm"> {item.email}</span>
                                    </p>
                                    <p>
                                        <b>Website : </b>
                                        <a href={item.website} target="_blank" className="text-slate-500 mt-0.5 text-sm" rel="noreferrer">
                                            {item.website}
                                        </a>
                                    </p>

                                    <div className="mt-5">
                                        <ButtonSecondary
                                            sizeClass="py-3 px-4 sm:py-3.5 sm:px-6"
                                            fontSize="text-sm font-medium"
                                            className="nc-shadow-lg"
                                        >
                                            <a
                                                href={item.googleLocation}
                                                target="_blank"
                                                className="flex items-center justify-start"
                                                rel="noreferrer"
                                            >
                                                <MapPin className="me-1" size={17} /> Google Map
                                            </a>
                                        </ButtonSecondary>
                                    </div>
                                </div>
                            </div>

                            {/* <span className="opacity-0 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
                        </div>
                    ))}
                </div>
            </div>

            <div className="my-20">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Reach Out To us
                </h2>
                <div className="container  mx-auto">
                    <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
                        <div className="max-w-sm space-y-8">
                            {info.map((item, index) => (
                                <div key={index}>
                                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">{item.title}</h3>
                                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">{item.desc}</span>
                                </div>
                            ))}
                            {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
                        </div>
                        <div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="grid grid-cols-2 gap-6">
                                    <label className="block">
                                        <Label>First Name</Label>
                                        <Input
                                            type="text"
                                            placeholder="Example Deo"
                                            style={{ borderColor: errors.firstName ? '#ea5455' : '' }}
                                            className="mt-1 rounded-md"
                                            {...register('firstName')}
                                            // onInvalid={(e) => {
                                            //   errors.password && true;
                                            // }}
                                            // {...register("full_name")}
                                        />
                                        {errors && errors.firstName && (
                                            <FormFeedback className="validation-error">{String(errors.firstName.message)}</FormFeedback>
                                        )}
                                    </label>
                                    <label className="block">
                                        <Label>Last Name</Label>
                                        <Input
                                            type="text"
                                            placeholder="Example Deo"
                                            className="mt-1 rounded-md"
                                            {...register('lastName')}
                                            style={{ borderColor: errors.lastName ? '#ea5455' : '' }}
                                            // onInvalid={(e) => {
                                            //   errors.password && true;
                                            // }}
                                            // {...register("full_name")}
                                        />
                                        {errors && errors.lastName && (
                                            <FormFeedback className="validation-error">{String(errors.lastName.message)}</FormFeedback>
                                        )}
                                    </label>
                                    <label className="block">
                                        <Label>Phone No</Label>
                                        <Input
                                            type="tel"
                                            maxLength={13}
                                            // minLength={13}
                                            style={{ borderColor: errors.mobile ? '#ea5455' : '' }}
                                            placeholder="(+1) 123-456-7890"
                                            className="mt-1 rounded-md"
                                            {...register('mobile')}
                                            // onInvalid={(e) => {
                                            //   errors.password && true;
                                            // }}
                                            // {...register("phone")}
                                        />
                                        {errors && errors.mobile && (
                                            <FormFeedback className="validation-error">{String(errors.mobile.message)}</FormFeedback>
                                        )}
                                    </label>
                                    <label className="block">
                                        <Label>Email</Label>
                                        <Input
                                            type="email"
                                            placeholder="example@example.com"
                                            style={{ borderColor: errors.email ? '#ea5455' : '' }}
                                            className="mt-1 rounded-md"
                                            {...register('email')}
                                        />
                                        {errors && errors.email && (
                                            <FormFeedback className="validation-error">{String(errors.email.message)}</FormFeedback>
                                        )}
                                    </label>
                                    <label className="block">
                                        <Label>Select Location</Label>
                                        <div className="mt-2">
                                            <Controller
                                                control={control}
                                                name="location"
                                                render={({ field }) => (
                                                    <Select
                                                        options={locationoptions}
                                                        isClearable
                                                        {...field}
                                                        // value={value}

                                                        // classNamePrefix="select"
                                                        // className={` block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                                                        // className={classNames("react-select", {
                                                        //   "is-invalid":
                                                        //     (errors?.location as Location)?.message ||
                                                        //     (errors?.location as Location)?.label
                                                        //       ?.message ||
                                                        //     ((errors?.location as Location)?.value &&
                                                        //       (errors?.location as Location)?.value
                                                        //         ?.message),
                                                        // })}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                borderColor: errors.location ? '#ea5455' : provided.borderColor,
                                                            }),
                                                        }}
                                                        // placeholder={"Select Address"}
                                                        // onChange={(val: any) => {
                                                        //   // Update the form state
                                                        //   onChange(val);
                                                        //   val && val?.value
                                                        //     ? updateAddressFields(val?.value)
                                                        //     : updateAddressFields(null);
                                                        // }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <FormFeedback className="validation-error">
                                            {(errors?.location as any)?.message ||
                                                (errors?.location as any)?.label?.message ||
                                                ((errors?.location as any)?.value && (errors?.location as any)?.value?.message)}
                                        </FormFeedback>
                                    </label>
                                    <label className="block">
                                        <Label>Subject</Label>
                                        <Input
                                            type="text"
                                            style={{ borderColor: errors.subject ? '#ea5455' : '' }}
                                            placeholder="Subject"
                                            className="mt-1 rounded-md"
                                            {...register('subject')}
                                        />
                                        {errors && errors.subject && (
                                            <FormFeedback className="validation-error">{String(errors.subject.message)}</FormFeedback>
                                        )}
                                    </label>
                                </div>
                                <div className="mt-6">
                                    <label className="block">
                                        <Label>Message</Label>
                                        <Textarea className="mt-1 rounded-md" rows={6} {...register('message')} />
                                    </label>
                                    <ButtonPrimary type="submit" className="mt-6 rounded-md">
                                        Send Message
                                    </ButtonPrimary>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageContact;

// **********////// Old Conatct /////// ***************
// import React, { FC } from "react";
// import { Helmet } from "react-helmet-async";
// import SocialsList from "shared/SocialsList/SocialsList";
// import Label from "components/Label/Label";
// import Input from "shared/Input/Input";
// import Textarea from "shared/Textarea/Textarea";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionPromo1 from "components/SectionPromo1";
// import AddressCard from "./AddressCard";
// import { Form } from "reactstrap";
// import NcImage from "shared/NcImage/NcImage";

// export interface PageContactProps {
//   className?: string;
// }

// const info = [
//   {
//     title: "🗺 ADDRESS",
//     desc: "2235 Thousand Oaks Dr Ste 102 San Antonio, TX 78232",
//   },
//   // {
//   //     title: '💌 EMAIL',
//   //     desc: 'mailto:nc.example@example.com',
//   // },
//   {
//     title: "☎ PHONE",
//     desc: "(210) 490-2733",
//   },
//   {
//     title: "🖨 Fax",
//     desc: "(210) 490-2416",
//   },
// ];

// const PageContact: FC<PageContactProps> = ({ className = "" }) => {
//   return (
//     <div
//       className={`nc-PageContact overflow-hidden ${className}`}
//       data-nc-id="PageContact"
//     >
//       <Helmet>
//         <title>Contact </title>
//       </Helmet>

//       <div className="page-title flex items-center justify-center bg-red-100">
//         <div className="content-box clearfix">
//           <div>
//             <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">
//               Reach Out To us
//             </h2>
//           </div>
//           <ul className="bread-crumb clearfix  flex items-center justify-center">
//             <li>
//               <a href="/">Home</a>
//             </li>
//             <li> Contact</li>
//           </ul>
//         </div>
//       </div>

//       <div className="container">
//         <div
//           // className={`CollectionCard2 group relative ${className}`}
//           style={{ cursor: "pointer" }}
//         >
//           <div className="relative flex flex-col">
//             <NcImage
//               containerClassName="aspect-w-8 aspect-h-5 bg-neutral-100 rounded-2xl overflow-hidden"
//               // src={featuredImage}
//               className="object-conatin rounded-2xl"
//             />
//           </div>

//           <span className="opacity-0  absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
//         </div>
//       </div>

//       <div className="my-20">
//         {/* <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
//                     Contact
//                 </h2> */}
//         <div className="container max-w-7xl mx-auto">
//           <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
//             <div className="max-w-sm space-y-8">
//               {info.map((item, index) => (
//                 <div key={index}>
//                   <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
//                     {item.title}
//                   </h3>
//                   <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
//                     {item.desc}
//                   </span>
//                 </div>
//               ))}
//               <div>
//                 <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
//                   🌏 SOCIALS
//                 </h3>
//                 <SocialsList className="mt-2" />
//               </div>
//             </div>
//             <div>
//               <Form className="grid grid-cols-1 gap-6">
//                 <label className="block">
//                   <Label>Full Name</Label>
//                   <Input
//                     type="text"
//                     placeholder="Example Deo"
//                     className="mt-1 rounded-md"
//                     // onInvalid={(e) => {
//                     //   errors.password && true;
//                     // }}
//                     // {...register("full_name")}
//                   />
//                   {/* {errors && errors.full_name && (
//                     <FormFeedback>{errors.full_name.message}</FormFeedback>
//                   )} */}
//                 </label>

//                 <label className="block">
//                   <Label>Phone No</Label>
//                   <Input
//                     type="tel"
//                     placeholder="(+1) 123-456-7890"
//                     className="mt-1 rounded-md"
//                     // onInvalid={(e) => {
//                     //   errors.password && true;
//                     // }}
//                     // {...register("phone")}
//                   />
//                   {/* {errors && errors.phone && (
//                     <FormFeedback>{errors.phone.message}</FormFeedback>
//                   )} */}
//                 </label>

//                 <label className="block">
//                   <Label>Email</Label>
//                   <Input
//                     type="email"
//                     mailto:placeholder="example@example.com"
//                     className="mt-1 rounded-md"
//                     // onInvalid={(e) => {
//                     //   errors.password && true;
//                     // }}
//                     // {...register("email")}
//                   />
//                   {/* {errors && errors.email && (
//                     <FormFeedback>{errors.email.message}</FormFeedback>
//                   )} */}
//                 </label>
//                 <label className="block">
//                   <Label>Message</Label>

//                   <Textarea className="mt-1 rounded-md" rows={6} />
//                 </label>
//                 <ButtonPrimary type="submit" className="mt-1 rounded-md">
//                   Send Message
//                 </ButtonPrimary>
//               </Form>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/ OTHER SECTIONS /}
//       {/* <div className="container">
//                 <div className="relative my-24 lg:my-32 py-24 lg:py-32">
//                     <BackgroundSection />
//                     <SectionPromo1 />
//                 </div>
//             </div> */}

//       <div className="container">
//         <h2 className="mt-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
//           Our Branches
//         </h2>
//         <div className="relative my-10 lg:my-10   lg:pb-20">
//           <AddressCard />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PageContact;
