interface ServiceConfig {
  API_URL: string;
}

var service: ServiceConfig = {
  API_URL: "http://192.168.29.123:8080",
};

// Uncomment and modify the conditional logic based on your environment if needed.
if (process.env.NODE_ENV === "production") {
  service.API_URL = "https://riteaway-api.teamfeeltech.com";
} else {
  service.API_URL = "http://192.168.29.123:8080";
}

export default service;
