import React, { FC, useState } from 'react';
import Logo from 'shared/Logo/Logo';
import MenuBar from 'shared/MenuBar/MenuBar';
import AvatarDropdown from './AvatarDropdown';
import Navigation from 'shared/Navigation/Navigation';
import CartDropdown from './CartDropdown';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { MapPin, Phone, ShoppingBag, ShoppingCart } from 'react-feather';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Button from 'shared/Button/Button';
export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
    const inputRef = React.createRef<HTMLInputElement>();
    const [showSearchForm, setShowSearchForm] = useState(false);
    const navigate = useNavigate();
    const TransferDetail = () => {
        // Open the link in a new tab
        window.open('https://riteaway1.drxrefill.com/store', '_blank');
    };
    const renderMagnifyingGlassIcon = () => {
        return (
            <svg width={22} height={22} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M22 22L20 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    };

    const renderSearchForm = () => {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    navigate('/page-search');
                }}
                className="flex-1 py-2 text-slate-900 dark:text-slate-100"
            >
                <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
                    {renderMagnifyingGlassIcon()}
                    <input
                        ref={inputRef}
                        type="text"
                        placeholder="Type and press enter"
                        className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
                        autoFocus
                    />
                    <button type="button" onClick={() => setShowSearchForm(false)}>
                        <XMarkIcon className="w-5 h-5" />
                    </button>
                </div>
                <input type="submit" hidden value="" />
            </form>
        );
    };

    const renderContent = () => {
        const googleMapsUrl = 'https://www.google.com/maps?q=2235+Thousand+Oaks+Dr+Ste+102+San+Antonio+TX+78232';
        const storeUrl = 'https://riteaway1.drxrefill.com/store';
        return (
            <div className="h-24 flex justify-between">
                <div className="flex items-center lg:hidden">
                    <MenuBar />
                </div>

                <div className="flex items-center ">
                    <Logo className="flex-shrink-0" />
                </div>

                <div className="hidden navbarhideden lg:flex justify-center ">
                    <div className=" navheadermargin">
                        <Navigation />
                    </div>
                </div>

                <div className="flex items-center  justify-end text-slate-700 dark:text-slate-100">
                    <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center">
                        <div>
                            <a href={storeUrl} target="_blank" rel="noopener noreferrer">
                                <ShoppingCart size={18} className="inline-block" color="#c72937" />
                            </a>
                        </div>
                    </div>
                    <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center">
                        {/* <div className="nc-Navigation flex items-center "> */}
                        <div>
                            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                                <MapPin size={18} className="inline-block" />
                                {/* <small> San Antonio, Texas</small> */}
                            </a>
                        </div>
                    </div>

                    <AvatarDropdown />
                </div>

                <div className="flex items-center hiddenbutton ">
                    <ButtonPrimary
                        className="nc-SectionHero2Item__button buttonheader button-custom"
                        // sizeClass="py-3 px-6 sm:py-5 sm:px-9"

                        onClick={TransferDetail}
                    >
                        <span>Shop Now</span>
                    </ButtonPrimary>
                </div>
            </div>
        );
    };

    return (
        <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
            <div className="navheader">{renderContent()}</div>
        </div>
    );
};

export default MainNav2Logged;
