import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet-async";
// import { getImgRd, getPersonNameRd, _getTitleRd } from "contains/fakeData";
import Tag from "shared/Tag/Tag";
import Logo from "../../images/logo/rite-away-logo.jpg";

const ProviderPage = () => {
  const renderHeader = () => {
    return (
      <>
        <header className="rounded-xl">
          <div className="mx-auto space-y-5">
            <div className="flex lg:justify-between">
              <h1 className="text-neutral-900 font-semibold  md:text-4xl md:!leading-[120%] lg:text-4xl  sm:text-xl ">
                GET TO KNOW RITEAWAY PHARMACY
              </h1>
              <Link to={"/contact"}>
                <ButtonPrimary className="rounded-md">Contact us</ButtonPrimary>
              </Link>
            </div>
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              For over a decade, Riteaway Pharmacy has been providing
              compounding services to physicians, clinics, and patients
              nationwide. Many of the medications we compound are used to treat
              complex conditions and unique disease states. Our
              specialty-trained compounding pharmacists work hand-in-hand with
              your doctor to customize medications to your needs. We are here to
              help answer questions, provide recommendations, and treat you with
              the care and compassion we would want for our family.
            </span>
          </div>
        </header>
      </>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm  sm:prose   dark:prose-invert"
        style={{ maxWidth: "100%" }}
      >
        <h2>WOMEN’S CARE</h2>
        <p>
          Women experience unique health challenges throughout their lives.
          We're dedicated to providing personalized solutions for menopause,
          pelvic pain, female sexual dysfunction, and more. Our non-hormonal
          supplements and custom-compounded medications offer relief and support
          at every stage.
        </p>

        <h2>MEN’S CARE</h2>
        <p>
          Many men face issues like low testosterone, low libido, and chronic
          prostatitis. Our compounded medications address these concerns
          effectively, including andropause treatments, erectile dysfunction
          solutions, and prostatitis therapies.
        </p>

        <h2>ALLERGY TESTING & TREATMENT</h2>
        <p>
          Get to the root of your allergies with access to allergist-quality
          testing and treatment programs. We offer natural therapies like
          sublingual drops and traditional injections, empowering you to manage
          your allergies effectively.
        </p>
        <h2>WEIGHT LOSS MANAGEMENT</h2>
        <p>
          We know weight management can be challenging. Our variety of weight
          loss solutions include vitamin injectables, appetite suppressants,
          satiety capsules, and even skin creams. Let us help you reach your
          goals and enjoy a healthier, happier life.
        </p>
        <h2>NUTRITION THERAPY</h2>
        <p>
          Nutrition Therapy provides your body with the vital nutrients it needs
          to thrive. We offer a diverse range of injectable and oral nutritional
          preparations, custom-tailored to meet your individual needs and
          preferences.
        </p>
        <h2>PAIN MANAGEMENT</h2>
        <p>
          Living with chronic pain shouldn't be a way of life. We offer a
          variety of compounded medications to manage pain effectively,
          including solutions for bone and joint conditions, muscle pain,
          neuropathies, and complex pain syndromes.
        </p>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle">
      <Helmet>
        <title>Providers </title>
      </Helmet>
      <div
        className="page-title flex items-center justify-center "
        style={{ backgroundColor: "#d6cdea" }}
      >
        <div className="content-box clearfix">
          <div>
            <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-2">
              Providers
            </h2>
          </div>
          <ul className="bread-crumb clearfix  flex items-center justify-center">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              {" "}
              <a href="/provider">Providers</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="providercontainer my-20">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2 xl:w-1/5  lg:pr-4  ">
            <div className="divide-y divide-slate-200 dark:divide-slate-700 ">
              <img src={Logo} alt="" />
            </div>
          </div>
          <div className="flex-shrink-0 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
          <div className="flex-1 ">
            {renderHeader()}

            <div className="nc-SingleContent mt-16  ">{renderContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderPage;
