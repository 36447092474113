// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

// ** API Imports
import axios from "../../../service/axios";

export const getuser = createAsyncThunk("getUserAllData/getuser", async () => {
  try {
    let response = await axios.get("/user", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response?.data;
  } catch (err) {
    return {};
  }
});

export const userSlice = createSlice({
  name: "getUserAllData",
  initialState: {
    user: [],
    useroption: [],
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getuser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
