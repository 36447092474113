import React, { FC } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
// import { Product, PRODUCTS } from "data/data";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import { Card } from "reactstrap";

export interface ProductCardProps {
  className?: string;
  data?: {
    label: string;
    value: string;
  };
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  isLiked,
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const navigate = useNavigate();
  const clickProductDetail = () => {
    navigate(`/products/${data?.value}`);
  };
  return (
    <>
      <Card onClick={clickProductDetail}>
        <div
          className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
          data-nc-id="ProductCard"
        >
          {/* <Link to={"/product-detail"} className="absolute inset-0"></Link> */}

          <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              // src={image}
              className="object-cover w-full h-full drop-shadow-xl"
            />
          </div>

          <div className="space-y-4 px-2.5 pt-5 pb-2.5">
            <div>
              <h2
                className={`nc-ProductCard__title text-base font-semibold transition-colors`}
              >
                {data?.label}
              </h2>
            </div>
          </div>
        </div>
      </Card>

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;
