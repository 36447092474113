import userSlice from "../containers/PageLogin/store/index";
import productSlice from "./store/products/index";
import productSliderSlice from "../components/store/products/index";
import cartSlice from "./store/cart/index";
import NavProductSlice from "../containers/store/NavitemProduct";

const crudApis = {
  userSlice,
  productSlice,
  productSliderSlice,
  NavProductSlice,
  cartSlice,
};

export default crudApis;
