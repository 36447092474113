import React, { FC, useState } from "react";
import imageRightPng from "../../images/pharmacy_image/sliderimage/Cut1.png";
import imageRightPng2 from "../../images/pharmacy_image/sliderimage/cut2.png";
import imageRightPng3 from "../../images/pharmacy_image/sliderimage/cut3.png";
import imageRightPng4 from "../../images/pharmacy_image/sliderimage/cut4.png";
import imageRightPng5 from "../../images/pharmacy_image/sliderimage/cut5.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import SliderContactForm from "../../components/StartCard/SliderContactForm";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}
export interface SectionHero2Props {
  className?: string;
}

const DATA: Hero2DataType[] = [
  {
    image: imageRightPng2,
    heading: "Retail Service",
    subHeading: "Independently owned pharmacy in San Antonio, Texas 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng3,
    heading: "Sterile/Non Sterile Compounding",
    subHeading: "Independently owned pharmacy in San Antonio, Texas 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng,
    heading: "Long Term Care Facilities",
    subHeading: "Independently owned pharmacy in San Antonio, Texas 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng4,
    heading: "Personalized Service",
    subHeading: "Independently owned pharmacy in San Antonio, Texas 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng5,
    heading: "Specialty Pharmacy Discount Programs",
    subHeading: "Independently owned pharmacy in San Antonio, Texas 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
];
let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const TransferDetail = () => {
    // Open the link in a new tab
    window.open("https://riteaway1.drxrefill.com/transfer-rx", "_blank");
  };
  const PatientDetail = () => {
    // Open the link in a new tab
    window.open("https://riteaway1.drxrefill.com/new-patient", "_blank");
  };
  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
    // isRunning ? null : null
  );
  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= DATA.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= DATA.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return DATA.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // =================
  const BG_COLORS = [
    "bg-red-50",
    "bg-green-50",
    "bg-yellow-50",
    "bg-blue-50",
    "bg-orange-50",
  ];

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = DATA[index];
    if (!isActive) {
      return null;
    }
    return (
      <div
        className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
        key={index}
      >
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
          {DATA.map((_, index) => {
            const isActive = indexActive === index;
            return (
              <div
                key={index}
                onClick={() => {
                  setIndexActive(index);
                  handleAfterClick();
                }}
                className={`relative px-1 py-1.5 cursor-pointer`}
              >
                <div
                  className={`relative lg:w-20 sm: w-12 h-1 shadow-sm rounded-md bg-white`}
                >
                  {isActive && (
                    <div
                      className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${
                        isActive ? " " : " "
                      }`}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <Prev
          className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickPrev={handleClickPrev}
        />
        <Next
          className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickNext={handleClickNext}
        />

        {/* BG */}
        <div className={`absolute inset-0 ${BG_COLORS[index]}`}>
          {/* <div className="absolute inset-0 bg-[#F7F0EA]"> */}
          <img
            className="absolute w-full h-full object-contain"
            src={backgroundLineSvg}
            alt="hero"
          />
        </div>

        <div className="relative container pb-0 pt-14 sm:pt-20 lg:py-44">
          <div className="lg:w-2/3 sm:w-full ">
            <div
              className={`relative z-[1] w-full max-w-3xl space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}
            >
              <div className="space-y-5 sm:space-y-6">
                <span className="nc-SectionHero2Item__subheading block text-base md:text-xl text-slate-700 font-medium">
                  {item.subHeading}
                </span>
                <h2
                  className="nc-SectionHero2Item__heading font-semibold text-3xl sm:text-4xl md:text-5xl xl:text-5xl 2xl:text-5xl !leading-[114%] text-slate-900"
                  style={{ height: "8vh" }}
                >
                  {item.heading}
                </h2>
              </div>

              <ButtonPrimary
                className="nc-SectionHero2Item__button  mr-5 buttonclass"
                sizeClass="py-3 px-6 sm:py-5 sm:px-9"
                onClick={PatientDetail}

                // href={item.btnLink as any}
              >
                <span>New Patient</span>
                {/* <span>
                  <svg
                    className="w-5 h-5 ml-2.5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span> */}
              </ButtonPrimary>
              <ButtonPrimary
                className="nc-SectionHero2Item__button buttonclass"
                sizeClass="py-3 px-6 sm:py-5 sm:px-9"
                onClick={TransferDetail}
              >
                <span>Transfer Rx</span>
              </ButtonPrimary>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
            <img
              className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
              src={item.image}
              alt={item.heading}
            />
          </div>
        </div>
      </div>
    );
  };

  return <>{DATA.map((_, index) => renderItem(index))}</>;
};

export default SectionHero2;
