import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../../service/axios";
import { isUserLoggedIn } from "utils/userloggin";
// Loader Components

// const router = useRouter();
// get all cart data
export const getCart = createAsyncThunk("cartSlice/getCart", async () => {
  // if (isUserLoggedIn()) {
  try {
    // showLoader(true);

    let response = await axios.get("/cart", {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // showLoader(false);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
  // } else {
  //     return Promise.resolve();
  // }
});
// multipart/form-data
// create new cart item
export const addtoCart = createAsyncThunk(
  "cartSlice/addtoCart",
  async (data: {}) => {
    // todo: update this dispatch and add condition for logged in user and guest user.

    if (isUserLoggedIn()) {
      try {
        // showLoader(true);
        let response = await axios.post("/cart", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        // toast.success(response?.data?.message || "Add to Cart successfull.");
        // showLoader(false);
        return data;
      } catch (err) {
        // showLoader(false);
        console.error("Error", err);
        // toast.error(err?.response?.data?.message || "Something went wrong!");
        return Promise.reject(err);
      }
    } else {
      //   toast.success("Add to Cart successfull.");
      return Promise.resolve(data);
    }
  }
);

//bulk create
// export const bulkCreate = createAsyncThunk(
//   "cartSlice/bulkCreate",
//   async (data, { dispatch }) => {
//     try {
//       showLoader(true);

//       let response = await axios.post("/bulk-cart", data, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       // toast.success(response?.data?.message || 'successfull.');
//       showLoader(false);
//       return Promise.resolve(response.data);
//     } catch (err) {
//       showLoader(false);
//       console.error("Error", err);
//       toast.error(err?.response?.data?.message || "Something went wrong!");
//       return Promise.reject(err);
//     }
//   }
// );

//upload image
// export const uploadImage = createAsyncThunk(
//   "cartSlice/uploadImage",
//   async (data, { dispatch }) => {
//     try {
//       showLoader(true);

//       let response = await axios.post("/upload-image", data, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       showLoader(false);
//       return Promise.resolve(response);
//     } catch (err) {
//       showLoader(false);
//       console.error("Error", err);
//       toast.error(err?.response?.data?.message || "Something went wrong!");
//       return Promise.reject(err);
//     }
//   }
// );

// export const updatetoCartQuantity = createAsyncThunk(
//   "cartSlice/updatetoCartQuantity",
//   async (data, { getState, dispatch }) => {
//     const state = getState();
//     const cart = state.cartSlice.cart;

//     if (isUserLoggedIn()) {
//       try {
//         showLoader(true);

//         let response = await axios.patch("/cart/" + data?.id, data, {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });

//         toast.success(
//           response?.data?.message || "Quantity updated successfull."
//         );
//         showLoader(false);
//         dispatch(getCart());
//         return Promise.resolve(response.data);
//       } catch (err) {
//         showLoader(false);
//         console.error("Error", err);
//         toast.error(err?.response?.data?.message || "Something went wrong!");
//         return Promise.reject(err);
//       }
//     } else {
//       const cartIndex = cart.findIndex((item) => item === data.product);
//       const updatedCart = [...cart]; // Create a new array to avoid mutating state directly
//       updatedCart[cartIndex] = {
//         ...updatedCart[cartIndex],
//         quantity: data.quantity, // Update the quantity property
//       };

//       toast.success("Quantity updated successfull.");
//       return Promise.resolve(updatedCart);
//     }
//   }
// );

// export const deletetoCart = createAsyncThunk(
//   "cartSlice/deletetoCart",
//   async (id, { dispatch, getState }) => {
//     const state = getState();
//     const cart = state.cartSlice.cart;
//     try {
//       showLoader(true);

//       let response = await axios.delete("/cart/" + id, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       toast.success(
//         response?.data?.message || "Delete from Cart successfully."
//       );
//       showLoader(false);
//       dispatch(getCart());
//       return Promise.resolve(response.data);
//     } catch (err) {
//       showLoader(false);
//       console.error("Error", err);
//       toast.error(err?.response?.data?.message || "Something went wrong!");
//       return Promise.reject(err);
//     }
//   }
// );

// export const deleteFromLocal = createAsyncThunk(
//   "cartSlice/deleteFromLocal",
//   async (product, { dispatch, getState }) => {
//     const state = getState();
//     const cart = state.cartSlice.cart;

//     const updatedCart = cart.filter((item, index) => item != product);

//     // secureSet("cartList", JSON.stringify(updatedCart));
//     return Promise.resolve(updatedCart);
//   }
// );
const initialCart = () => {
  try {
    var item = window.localStorage.getItem("cartList");
    if (Array.isArray(item)) return item;
    return [];
  } catch (err) {
    console.error("Error in initialCart", err);
    return [];
  }
};

// get quantity
// export const getQuantity = createAsyncThunk(
//   "cartSlice/getQuantity",
//   async (id) => {
//     try {
//       showLoader(true);

//       let response = await axios.get("/stock-check/" + id, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       showLoader(false);
//       return Promise.resolve(response.data);
//     } catch (err) {
//       showLoader(false);
//       if (err?.response?.status != 401) {
//         toast.error(err.response?.data?.message || "Something went wrong!");
//       }

//       return Promise.reject(err);
//     }
//   }
// );
export const cartSlice = createSlice({
  name: "cartSlice",
  initialState: {
    cart: initialCart(),
    stockQuantity: [],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(addtoCart.fulfilled, (state, action) => {
      // state.cart.push(action.payload);
      //   secureSet("cartList", state.cart);
      return;
    });
  },
});
// export const { clearCartList } = cartSlice.actions;
export default cartSlice.reducer;
