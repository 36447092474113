import React from "react";
import { MapPin } from "react-feather";
import ButtonSecondary from "shared/Button/ButtonSecondary";
const AddressCard = () => {
  const A_FEATURES = [
    {
      id: 1,
      color: "bg-red-50",
      name: "RITE AWAY PHARMACY & MEDICAL SUPPLY",
      addressLine1: "2235 THOUSAND OAKS DR #102",
      addressLine2: "SAN ANTONIO, TX  78232",
      countryName: "BEXAR",
      phoneNo: "(210) 490-2733",
      googleLocation: "https://maps.app.goo.gl/Y5uhtafEEmesaNPb6",
    },
    {
      id: 2,
      color: "bg-amber-50",
      name: "RITE-AWAY PHARMACY",
      addressLine1: "426 CASTROVILLE RD #5",
      addressLine2: "SAN ANTONIO, TX  78207",
      countryName: "BEXAR",
      phoneNo: "(210) 433-9991",
      googleLocation: "https://maps.app.goo.gl/DhVMeQGyDRj2ABmQ9",
    },
    {
      id: 3,
      color: "bg-sky-50",
      name: "RITE AWAY PHARMACY & MEDICAL SUPPLY",
      addressLine1: "730 W STASSNEY LN #160",
      addressLine2: "AUSTIN, TX  78745",
      countryName: "TRAVIS",
      phoneNo: "(512) 827-2600",
      googleLocation: "https://maps.app.goo.gl/TNYiqactu3UKJqbU7",
    },
    {
      id: 4,
      color: "bg-purple-50",
      name: "RITE-AWAY PHARMACY & MEDICAL SUPPLY #2",
      addressLine1: "2716 SW MILITARY DR STE 102",
      addressLine2: "AUSTIN, TX  78745",
      countryName: "BEXAR",
      phoneNo: "(210) 927-3742",
      googleLocation: "https://maps.app.goo.gl/2TLsSAoB9HTsZQv26",
    },
    {
      id: 5,
      color: "bg-orange-50",
      name: "RITE-AWAY PHARMACY & MEDICAL SUPPLY #4",
      addressLine1: "4203 JAMES CASEY ST STE A",
      addressLine2: "AUSTIN, TX  78745",
      countryName: "TRAVIS",
      phoneNo: "(512) 827-2250",
      googleLocation: "https://maps.app.goo.gl/LPXgzEgCahKkGyUo8",
    },
    {
      id: 6,
      color: "bg-pink-100",
      name: "Liberty Pharmacy",
      addressLine1: "8650 SPICEWOOD SPRINGS RD",
      addressLine2: "SUITE 106",
      countryName: "TRAVIS",
      phoneNo: "(512) 827-2250",
      googleLocation: "https://maps.app.goo.gl/zY3hBC7xbXN1fa2N9",
    },
    {
      id: 7,
      color: "bg-green-50",
      name: "RITE RX PHARMACY CORP",
      addressLine1: "6918 STIRLING RD",
      addressLine2: "HOLLYWOOD, FL  33024",
      countryName: "BROWARD",
      phoneNo: "(800) 931-6279",
      googleLocation: "https://maps.app.goo.gl/o77TBz4cHhf2KLnJ8",
    },
  ];

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10 sm:gap-16 xl:gap-10">
      {A_FEATURES.map((item, index) => {
        return (
          <div
            key={index}
            className={`flex flex-col p-5 rounded-2xl ${item.color} dark:bg-opacity-90`}
          >
            {/* <div dangerouslySetInnerHTML={{ __html: item.svg }}></div> */}
            <div className="mt-2.5">
              <p className="font-semibold text-slate-900 ">
                <b>{item.name}</b>
              </p>
              <p className="font-semibold text-slate-900 mt-3">
                <b>Address:</b>
                <span>
                  <p className="text-slate-500 mt-0.5 text-sm">
                    {item.addressLine1}
                  </p>
                  <p className="text-slate-500 mt-0.5 text-sm">
                    {item.addressLine2}
                  </p>
                </span>
              </p>

              <p className="mt-2.5">
                <b>County : </b>
                <span className="text-slate-500 mt-0.5 text-sm">
                  {item.countryName}
                </span>
              </p>
              <p>
                <b>Phone : </b>{" "}
                <span className="text-slate-500 mt-0.5 text-sm">
                  {" "}
                  {item.phoneNo}
                </span>
              </p>

              <div className="mt-5">
                <ButtonSecondary
                  sizeClass="py-3 px-4 sm:py-3.5 sm:px-6"
                  fontSize="text-sm font-medium"
                  className="nc-shadow-lg"
                >
                  <a
                    href={item?.googleLocation}
                    target="_blank"
                    className="flex items-center justify-start"
                  >
                    <MapPin className="me-1" size={17} /> Google Map
                  </a>
                </ButtonSecondary>
              </div>
            </div>
          </div>
        );
      })}

      {/* <div className="absolute inset-x-0 border-t top-1/2 -translate-y-1/2 border-slate-300"></div>
      <div className="absolute inset-y-0 border-l left-1/2 -translate-x-1/2 border-slate-300"></div> */}
    </div>
  );
};

export default AddressCard;
