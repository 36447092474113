import React, { FC } from "react";
import Heading from "../Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
import Pickup_img from "../../images/pharmacy_image/Free_shipping/Layer3.png";
import FreeDelivery_img from "../../images/pharmacy_image/Free_shipping/FreeShip.png";
import JoinToday_img from "../../images/pharmacy_image/shop_way/join-today.png";
import FreeShipping_img from "../../images/pharmacy_image/Free_shipping/FAsta.png";
import Payment_img from "../../images/pharmacy_image/shop_way/Payment.png";

import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";
export interface SectionHowItWorkProps {
  className?: string;
  data?: (typeof DEMO_DATA)[0][];
}

const DEMO_DATA = [
  {
    id: 1,
    img: Pickup_img,
    imgDark: Pickup_img,
    title: "Filled in 15 minutes",
    desc: "Smart filtering and suggestions make it easy to find",
  },
  // {
  //   id: 2,
  //   img: FreeDelivery_img,
  //   imgDark: FreeDelivery_img,
  //   title: "Same day delivery",
  //   desc: "Easily select the correct items and add them to the cart",
  // },
  {
    id: 2,
    img: FreeDelivery_img,
    imgDark: FreeDelivery_img,
    title: "Free Delivery",
    desc: "Easily select the correct items and add them to the cart",
  },
  // {
  //   id: 3,
  //   img: JoinToday_img,
  //   imgDark: JoinToday_img,
  //   title: "Join us today",
  //   desc: "The carrier will confirm and ship quickly to you",
  // },
  {
    id: 3,
    img: FreeShipping_img,
    imgDark: FreeShipping_img,
    title: "Fast shipping",
    desc: "Have fun and enjoy your 5-star quality products",
  },
  // {
  //   id: 5,
  //   img: Payment_img,
  //   imgDark: Payment_img,
  //   title: " my® Credit Card ",
  //   desc: "Have fun and enjoy your 5-star quality products",
  // },
];

const ShopWay: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div>
      <Heading
        className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50 nc-p-r-container "
        desc=""
      >
        Shop Rite Away Pharmacy your way
      </Heading>

      <div>
        <div
          className={`nc-SectionHowItWork ${className}`}
          data-nc-id="SectionHowItWork"
        >
          <div className="relative grid sm:grid-cols-2 lg:grid-cols-3 gap-10 sm:gap-16 xl:gap-20">
            {/* <img
              className="hidden md:block absolute inset-x-0 top-5"
            //   src={VectorImg}
              alt="vector"
            /> */}
            {data.map((item: (typeof DEMO_DATA)[number], index: number) => (
              <div
                key={item.id}
                className="relative flex flex-col items-center max-w-xs mx-auto"
              >
                <NcImage
                  containerClassName="mb-4 sm:mb-5 max-w-[90px] mx-auto"
                  className="rounded-0xl"
                  src={item.img}
                />
                <div className="text-center  space-y-5">
                  {/* <Badge
                                        name={`Step ${index + 1}`}
                                        color={!index ? 'red' : index === 1 ? 'indigo' : index === 2 ? 'yellow' : 'purple'}
                                    /> */}
                  <h3 className="text-base font-semibold">{item.title}</h3>
                  {/* <span className="block text-slate-600 dark:text-slate-400 text-sm leading-6">{item.desc}</span> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopWay;
