import Glide from '@glidejs/glide';
import CardCategory2 from 'components/CardCategories/Featurecategories';
import Heading from 'components/Heading/Heading';
import { FC, useEffect, useId } from 'react';
import MedicalSupplies from '../../images/pharmacy_image/Featureimage/medicalss2.png';
import Vitamins from '../../images/pharmacy_image/Featureimage/Vitamins2.jpg';
import FirstAid from '../../images/pharmacy_image/Featureimage/FirstAid1.jpg';

export interface CardCategoryData {
    name: string;
    desc: string;
    img: string;
    color?: string;
}
const CATS: CardCategoryData[] = [
    {
        name: '',
        desc: 'Medical Supplies',
        img: MedicalSupplies,
        color: 'bg-indigo-100',
    },
    {
        name: '',
        desc: 'Vitamin & Supplements',
        img: Vitamins,
        color: 'bg-slate-100',
    },
    {
        name: '',
        desc: 'First Aid & Other Accessories',
        img: FirstAid,
        color: 'bg-sky-100',
    },
    // {
    //     name: '',
    //     desc: 'Fall & Winter',
    //     img: department4Png,
    //     color: 'bg-orange-100',
    // },
    // {
    //     name: '',
    //     desc: 'Walgreens Brand',
    //     img: department1Png,
    //     color: 'bg-indigo-100',
    // },
];
export interface SectionSliderCategoriesProps {
    className?: string;
    itemClassName?: string;
    heading?: string;
    subHeading?: string;
    data?: CardCategoryData[];
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
    heading = 'Customer Favorites',
    subHeading = '',
    className = '',
    itemClassName = '',
    data = CATS,
}) => {
    const id = useId();
    const UNIQUE_CLASS = 'glidejs' + id.replace(/:/g, '_');

    useEffect(() => {
        // @ts-ignore
        const OPTIONS: Glide.Options = {
            perView: 3,
            gap: 32,
            bound: true,
            breakpoints: {
                1280: {
                    perView: 4,
                },
                1024: {
                    gap: 20,
                    perView: 3.4,
                },
                768: {
                    gap: 20,
                    perView: 3,
                },
                640: {
                    gap: 20,
                    perView: 2.3,
                },
                500: {
                    gap: 20,
                    perView: 1.4,
                },
            },
        };

        let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
        slider.mount();
        return () => {
            slider.destroy();
        };
    }, [UNIQUE_CLASS]);

    return (
        <div className={`nc-SectionSliderCategories ${className}`}>
            <div className={`${UNIQUE_CLASS} flow-root`}>
                <Heading desc={subHeading} hasNextPrev>
                    {heading}
                </Heading>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {data.map((item, index) => (
                            <li key={index} className={`glide__slide `}>
                                <CardCategory2 featuredImage={item.img} name={item.name} desc={item.desc} bgClass={item.color} />
                            </li>
                        ))}
                        <li className={`glide__slide `}>
                            <div className={`CollectionCard2 group relative ${className}`} style={{ cursor: 'pointer' }}>
                                <div className="relative rounded-2xl overflow-hidden lg:h-[255px] h-[150px]">
                                    <div className="lg:h-[255px] h-[150px] bg-black/5 dark:bg-neutral-800"></div>
                                    <div className="absolute inset-y-6 inset-x-10  flex flex-col items-center justify-center">
                                        <div className="flex items-center justify-center relative">
                                            <span className="text-xl font-semibold">More items</span>
                                            <svg
                                                className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M12 20.4999V3.66992"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        <span className="text-sm mt-1">Show me more</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SectionSliderCategories;
