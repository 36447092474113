import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetCategories from "./WidgetCategories";
import WidgetPosts from "./WidgetPosts";
import Card3 from "./Card3";

//
export interface SectionLatestPostsProps {
  className?: string;
  postCardName?: "card3";
}
const Blog_DEMO: any = [
  {
    id: "1",
    title: `Embracing Wellness: Your Guide to a Healthier Lifestyle with Rite Away Pharmacy`,
    desc: "Welcome to the Rite Away Pharmacy blog, where your health and well-being are our top priorities. In today's fast-paced world, maintaining a healthy lifestyle is crucial, and at Rite Away Pharmacy, we're dedicated to supporting you on this journey. Join us as we explore key insights and tips to unlock a healthier you.",
  },
];
const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  postCardName = "card3",
  className = "",
}) => {
  return (
    <div className={`nc-SectionLatestPosts relative py-10`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full">
          {/* <Heading>Latest Articles 🎈</Heading> */}

          <div>
            {/* <div className="w-full">
              <h4 className=" !leading-tight font-semibold text-neutral-900 my-5  xl:text-3xl dark:text-neutral-100">
                Embracing Wellness: Your Guide to a Healthier Lifestyle with
                Rite Away Pharmacys
              </h4>
              <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 text-justify pt-3">
                Welcome to the Rite Away Pharmacy blog, where your health and
                well-being are our top priorities. In today's fast-paced world,
                maintaining a healthy lifestyle is crucial, and at Rite Away
                Pharmacy, we're dedicated to supporting you on this journey.
                Join us as we explore key insights and tips to unlock a
                healthier you.
              </span>
            </div> */}
          </div>
          <div className={`grid gap-6 md:gap-8 grid-cols-1 py-10`}>
            {Blog_DEMO.map((item: any) => (
              <Card3 key={item.id} data={item} />
            ))}
          </div>
          {/* <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
            <ButtonPrimary>Show me more</ButtonPrimary>
          </div> */}
        </div>
        {/* <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          <WidgetCategories />
          <WidgetPosts />
        </div> */}
      </div>
      {/* <div>
        <div className="w-full">
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 text-justify pt-3">
            At Rite Away Pharmacy, we believe that your journey to wellness
            should be seamless and supported. From online prescription refills
            to a wealth of health resources, we're here to empower you on your
            path to a healthier lifestyle. Join us in embracing wellness –
            because your health matters, every step of the way.
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default SectionLatestPosts;
