import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div className="my-20">
      <div
        className={`nc-SectionHero relative ${className}`}
        data-nc-id="SectionHero"
      >
        <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
          <div className="w-full lg:w-[50%] space-y-15 gap-4">
            <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 my-5 md:text-4xl xl:text-5xl dark:text-neutral-100">
              {heading}
            </h2>
            {/* <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">{subHeading}</span> */}
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 text-justify">
              {subHeading}
            </span>
            {!!btnText && (
              <ButtonPrimary href="/login">{btnText}</ButtonPrimary>
            )}
          </div>
          <div className="w-full lg:w-[40%]">
            <img className="w-full rounded-md" src={rightImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
