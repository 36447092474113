import React, { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import PulseAnimation from "./Loader/Pvccard"; // Import or create your loader component

import MetalCollections from "./MetalsCollections";
import { Link } from "react-router-dom";
import { DEMO_LARGE_PRODUCTS } from "./SectionSliderLargeProduct2";
import { getSliderProduct } from "./store/products";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { Product } from "redux/store/products/class";

export interface SectionSliderLargeProductProps {
  className?: string;
  itemClassName?: string;
  cardStyle?: "style1" | "style2";
}

const SectionSliderLargeProduct: FC<SectionSliderLargeProductProps> = ({
  className = "",
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const [isLoading, setIsLoading] = useState(true);
  const sliderRef = useRef(null);

  const sliderproduct: Product[] = useSelector(
    (state: RootState) => state?.productSliderSlice.productslider
  );
  // console.log(DEMO_LARGE_PRODUCTS);
  // console.log(sliderproduct.length);
  const dispatch = useDispatch<AppDispatch>();

  const SilderProduct = async () => {
    setIsLoading(true);
    await dispatch(getSliderProduct());
    setIsLoading(false);
  };
  useEffect(() => {
    if (!sliderRef.current) {
      return () => {};
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.15,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },

        500: {
          gap: 20,
          perView: 1,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [UNIQUE_CLASS, isLoading, sliderRef]);

  useEffect(() => {
    SilderProduct();
  }, []);

  return (
    <div className={`nc-SectionSliderLargeProduct ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading isCenter={false} hasNextPrev>
          Chosen by our experts : PVC Cards
        </Heading>

        {isLoading && (
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {sliderproduct &&
                sliderproduct.slice(0, 3).map((product, index) => (
                  <li className={`glide__slide`} key={index}>
                    <PulseAnimation />
                  </li>
                ))}
              <li className={`glide__slide   `}>
                <Link
                  to={"/pvc-card/subcategory"}
                  className="block relative group"
                >
                  <div className="relative rounded-2xl overflow-hidden h-[410px]">
                    <div className="h-[410px] bg-black/5 dark:bg-neutral-800"></div>
                    <div className="absolute inset-y-6 inset-x-10  flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center relative">
                        <span className="text-xl font-semibold">
                          More items
                        </span>
                        <svg
                          className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 20.4999V3.66992"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <span className="text-sm mt-1">Show me more</span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        )}

        {!isLoading && (
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {sliderproduct &&
                sliderproduct.slice(0, 3).map((product, index) => (
                  <li className={`glide__slide`} key={index}>
                    <MetalCollections
                      id={product.id}
                      name={product.name}
                      price={product.price}
                      image={
                        product.ProductImageMappings[0]?.ProductImage?.path
                      }
                      // imgs={product.ProductImageMappings[0]?.ProductImage?.path}
                      // description={product.desc}
                    />
                  </li>
                ))}

              <li className={`glide__slide   `}>
                <Link
                  to={"/pvc-card/subcategory"}
                  className="block relative group"
                >
                  <div className="relative rounded-2xl overflow-hidden h-[410px]">
                    <div className="h-[410px] bg-black/5 dark:bg-neutral-800"></div>
                    <div className="absolute inset-y-6 inset-x-10  flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center relative">
                        <span className="text-xl font-semibold">
                          More items
                        </span>
                        <svg
                          className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 20.4999V3.66992"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <span className="text-sm mt-1">Show me more</span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionSliderLargeProduct;
