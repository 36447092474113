import { NavItemType } from 'shared/Navigation/NavigationItem';
import ncNanoId from 'utils/ncNanoId';
import { ShoppingBag } from 'react-feather';

export const MEGAMENU_TEMPLATES: NavItemType[] = [
    {
        id: ncNanoId(),
        href: '/',
        name: 'Home Page',
        children: [
            { id: ncNanoId(), href: '/', name: 'Home  1' },
            { id: ncNanoId(), href: '/home2', name: 'Home  2', isNew: true },
            { id: ncNanoId(), href: '/', name: 'Header  1' },
            { id: ncNanoId(), href: '/home2', name: 'Header  2', isNew: true },
            { id: ncNanoId(), href: '/', name: 'Coming Soon' },
        ],
    },
    {
        id: ncNanoId(),
        href: '/',
        name: 'Shop Pages',
        children: [
            { id: ncNanoId(), href: '/page-collection', name: 'Category Page 1' },
            { id: ncNanoId(), href: '/page-collection-2', name: 'Category Page 2' },
            { id: ncNanoId(), href: '/product-detail', name: 'Product Page 1' },
            { id: ncNanoId(), href: '/product-detail-2', name: 'Product Page 2' },
            { id: ncNanoId(), href: '/cart', name: 'Cart Page' },
            { id: ncNanoId(), href: '/checkout', name: 'Checkout Page' },
        ],
    },
    {
        id: ncNanoId(),
        href: '/',
        name: 'Other Pages',
        children: [
            { id: ncNanoId(), href: '/checkout', name: 'Checkout Page' },
            { id: ncNanoId(), href: '/page-search', name: 'Search Page' },
            { id: ncNanoId(), href: '/cart', name: 'Cart Page' },
            { id: ncNanoId(), href: '/account', name: 'Accout Page' },
            { id: ncNanoId(), href: '/account-my-order', name: 'Order Page' },
            { id: ncNanoId(), href: '/subscription', name: 'Subscription' },
        ],
    },
    {
        id: ncNanoId(),
        href: '/',
        name: 'Blog Page',
        children: [
            { id: ncNanoId(), href: '/blog', name: 'Blog Page' },
            { id: ncNanoId(), href: '/blog-single', name: 'Blog Single' },
            { id: ncNanoId(), href: '/about', name: 'About Page' },
            { id: ncNanoId(), href: '/contact', name: 'Contact Page' },
            { id: ncNanoId(), href: '/login', name: 'Login' },
            { id: ncNanoId(), href: '/signup', name: 'Signup' },
        ],
    },
];

const OTHER_Location_CHILD: NavItemType[] = [
    {
        id: ncNanoId(),
        href: '/',
        name: 'New Pateint',
        externalLink: 'https://riteaway1.drxrefill.com/new-patient',
    },
    {
        id: ncNanoId(),
        href: '/',
        name: 'Transfer Rx',
        externalLink: 'https://riteaway1.drxrefill.com/transfer-rx',
    },
];
const OTHER_Location_CHILD2: NavItemType[] = [
    {
        id: ncNanoId(),
        href: '/',
        name: 'San Antonio',
        type: 'dropdown',
        children: [
            {
                id: ncNanoId(),
                href: '/',
                name: 'Rite Away Pharmacy',
                externalLink:
                    'https://www.google.com/maps/place/Rite+Away+Pharmacy+%26+Med+Supplies/@29.587707,-98.4553283,16.68z/data=!4m14!1m7!3m6!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!2sRite+Away+Pharmacy+%26+Med+Supplies!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94!3m5!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94?authuser=0&entry=ttu',

                // isNew: true,
            },
            {
                id: ncNanoId(),
                href: '/',
                name: 'Rite Away Specialty Pharmacy',
                externalLink:
                    'https://www.google.com/maps/place/Rite+Away+Pharmacy+%26+Med+Supplies/@29.587707,-98.4553283,16.68z/data=!4m14!1m7!3m6!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!2sRite+Away+Pharmacy+%26+Med+Supplies!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94!3m5!1s0x865c8a47d72c8225:0xf860415ef74dcdf9!8m2!3d29.5878006!4d-98.4518307!16s%2Fg%2F1tfw3g94?authuser=0&entry=ttu',
                isNew: true,
            },
            {
                id: ncNanoId(),
                href: '/',
                name: 'Rite-Away Pharmacy',
                externalLink:
                    'https://www.google.com/maps/place/Rite-Away+Pharmacy+%26+Medical+Supply+%23+2/@29.3561578,-98.5426628,16z/data=!4m14!1m7!3m6!1s0x865c59b9c5f35a11:0xd1ee08e1b8943f3e!2sRite-Away+Pharmacy+%26+Medical+Supply+%23+2!8m2!3d29.3561531!4d-98.5400879!16s%2Fg%2F1tg8k48g!3m5!1s0x865c59b9c5f35a11:0xd1ee08e1b8943f3e!8m2!3d29.3561531!4d-98.5400879!16s%2Fg%2F1tg8k48g?authuser=0&entry=ttu',
                isNew: true,
            },
            {
                id: ncNanoId(),
                href: '/',
                name: 'Rite Away Pharmacy 6',
                externalLink:
                    'https://www.google.com/maps/place/Rite-Away+Pharmacy+%26+Medical+Supply/@29.418367,-99.6983528,9z/data=!3m1!5s0x865c593479a1b64f:0xbd577434f2ab1319!4m10!1m2!2m1!1sRite-Away+Pharmacy+near+Castroville+Road,+San+Antonio,+TX!3m6!1s0x865c59347979f7eb:0x1a07841fb7eaf707!8m2!3d29.4183484!4d-98.544873!15sCjlSaXRlLUF3YXkgUGhhcm1hY3kgbmVhciBDYXN0cm92aWxsZSBSb2FkLCBTYW4gQW50b25pbywgVFgiA4gBAVo5IjdyaXRlIGF3YXkgcGhhcm1hY3kgbmVhciBjYXN0cm92aWxsZSByb2FkIHNhbiBhbnRvbmlvIHR4kgEIcGhhcm1hY3ngAQA!16s%2Fg%2F1tm0c6vx?authuser=0&entry=ttu',
                isNew: true,
            },
        ],
    },
    {
        id: ncNanoId(),
        href: '/',
        name: 'Austin',
        type: 'dropdown',
        children: [
            {
                id: ncNanoId(),
                href: '/',
                name: 'Rite Away Pharmacy 3',
                externalLink:
                    'https://www.google.com/maps/place/Rite-Away+Pharmacy+%26+Medical+Supply+%233/@30.2094111,-97.7832817,16z/data=!4m14!1m7!3m6!1s0x8644b4ab77674667:0xd9a93f6da62f88b!2sRite-Away+Pharmacy+%26+Medical+Supply+%233!8m2!3d30.2094111!4d-97.7832817!16s%2Fg%2F1hg4yy69j!3m5!1s0x8644b4ab77674667:0xd9a93f6da62f88b!8m2!3d30.2094111!4d-97.7832817!16s%2Fg%2F1hg4yy69j?authuser=0&entry=ttu',
                isNew: true,
            },
            {
                id: ncNanoId(),
                href: '/',
                name: 'Rite Away Pharmacy 4',
                externalLink:
                    'https://www.google.com/maps/place/Rite-Away+Pharmacy+and+Medical+Supply+%234/@30.2252827,-98.9273805,9z/data=!4m10!1m2!2m1!1sRite-Away+Pharmacy+near+austin!3m6!1s0x8644b446ca9d5e79:0xc60f33eeb95deebc!8m2!3d30.2252827!4d-97.773816!15sCh5SaXRlLUF3YXkgUGhhcm1hY3kgbmVhciBhdXN0aW4iA4gBAVogIh5yaXRlIGF3YXkgcGhhcm1hY3kgbmVhciBhdXN0aW6SAQhwaGFybWFjeeABAA!16s%2Fg%2F1s04d3gmr?authuser=0&entry=ttu',
                isNew: true,
            },
            {
                id: ncNanoId(),
                href: '/',
                name: 'Liberty Pharmacy',
                externalLink:
                    'https://www.google.com/maps/place/Liberty+Pharmacy/@30.4329859,-98.92519,9z/data=!4m10!1m2!2m1!1sliberty+pharmacy!3m6!1s0x8644ccd85ff438f3:0xb2c4cb237d9194db!8m2!3d30.4330058!4d-97.7715715!15sChBsaWJlcnR5IHBoYXJtYWN5WhIiEGxpYmVydHkgcGhhcm1hY3mSAQhwaGFybWFjeeABAA!16s%2Fg%2F1wb8v28c?entry=ttu',
                isNew: true,
            },
        ],
    },
    // {
    //     id: ncNanoId(),
    //     href: '/',
    //     name: 'Travis',
    //     type: 'dropdown',
    //     children: [
    //         {
    //             id: ncNanoId(),
    //             href: '/',
    //             name: 'Liberty Pharmacy',
    //             externalLink:
    //                 'https://www.google.com/maps/place/Liberty+Pharmacy/@30.4329859,-98.92519,9z/data=!4m10!1m2!2m1!1sliberty+pharmacy!3m6!1s0x8644ccd85ff438f3:0xb2c4cb237d9194db!8m2!3d30.4330058!4d-97.7715715!15sChBsaWJlcnR5IHBoYXJtYWN5WhIiEGxpYmVydHkgcGhhcm1hY3mSAQhwaGFybWFjeeABAA!16s%2Fg%2F1wb8v28c?entry=ttu',
    //             isNew: true,
    //         },
    //         // {
    //         //   id: ncNanoId(),
    //         //   href: "/product-detail-2",
    //         //   name: "Product detail 2",
    //         // },
    //     ],
    // },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
    {
        id: ncNanoId(),
        href: '/',
        name: 'Home',
    },

    {
        id: ncNanoId(),
        href: '/about',
        name: 'About',
    },

    {
        id: ncNanoId(),
        href: '/service',
        name: 'Services',
    },
    {
        id: ncNanoId(),
        href: '/providers',
        name: 'Providers',
    },
    // {
    //   id: ncNanoId(),
    //   href: "/location",
    //   name: "Location",
    //   // type: "dropdown",
    //   // children: OTHER_Location_CHILD,
    // },
    {
        id: ncNanoId(),
        href: '/blog-single',
        name: 'Blog',
    },
    {
        id: ncNanoId(),
        href: '/',
        name: 'Location',
        type: 'dropdown',
        children: OTHER_Location_CHILD2,
    },
    // {
    //   id: ncNanoId(),
    //   href: "/",
    //   name: "Links",
    //   type: "dropdown",
    //   children: OTHER_Location_CHILD,
    // },
    {
        id: ncNanoId(),
        href: '/contact',
        name: 'Contact',
    },
    // {
    //   id: ncNanoId(),
    //   href: "/",
    //   name: "Shop",
    //   externalLink: "https://riteaway1.drxrefill.com/store",
    //   targetBlank: true,
    //   isNew: true,
    // },
];
