import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import SocialsList from 'shared/SocialsList/SocialsList';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet-async';
import { _getImgRd, _getPersonNameRd, _getTitleRd } from 'contains/fakeData';
import Tag from 'shared/Tag/Tag';
import Blogimage from '../../images/pharmacy_image/Featureimage/pexels-artem-podrez-4492048.jpg';

const BlogSingle = () => {
    const renderHeader = () => {
        return (
            <>
                <header className="container rounded-xl">
                    <div className="mx-auto space-y-5">
                        <h1
                            className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl   "
                            title="Quiet ingenuity: 120,000 lunches and counting"
                        >
                            Embracing Wellness: Your Guide to a Healthier Lifestyle with Rite Away Pharmacy
                        </h1>
                        <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                            Welcome to the Rite Away Pharmacy blog, where your health and well-being are our top priorities. In today's
                            fast-paced world, maintaining a healthy lifestyle is crucial, and at Rite Away Pharmacy, we're dedicated to
                            supporting you on this journey. Join us as we explore key insights and tips to unlock a healthier you
                        </span>

                        <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
                        <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
                                <Avatar containerClassName="flex-shrink-0" sizeClass="w-8 h-8 sm:h-11 sm:w-11" />
                                <div className="ml-3">
                                    <div className="flex items-center">
                                        <a className="block font-semibold" href="##">
                                            Rite Away Pharmacy Team
                                        </a>
                                    </div>
                                    <div className="text-xs mt-[6px]">
                                        <span className="text-neutral-700 dark:text-neutral-300">Jan 24, 2024</span>
                                        <span className="mx-2 font-semibold">·</span>
                                        <span className="text-neutral-700 dark:text-neutral-300">3 min read</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mt-3 sm:mt-1.5 sm:ml-3">
                <SocialsList />
              </div> */}
                        </div>
                    </div>
                </header>
            </>
        );
    };

    const renderContent = () => {
        return (
            <div id="single-entry-content" className="prose prose-sm  sm:prose lg:prose-lg  dark:prose-invert" style={{ maxWidth: '100%' }}>
                <h2>The Rite Away Commitment</h2>
                <p>
                    At Rite Away Pharmacy, we go beyond being just a pharmacy – we are your partners in wellness. Our commitment extends to
                    personalized care and support from our experienced team of pharmacists. We're here to guide you on your unique path to
                    wellness.
                </p>
                {/* <p>Something a wise person once told me about typography is:</p>
        <blockquote>
          <p>
            Typography is pretty important if you don't want your stuff to look
            like trash. Make it good then it won't be bad.
          </p>
        </blockquote>
        <p>
          It's probably important that images look okay here by default as well:
        </p> */}
                {/* <figure>
          <img
            src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
            officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
            distinctio veritatis sapiente
          </figcaption>
        </figure>
        <p>
          Now I'm going to show you an example of an unordered list to make sure
          that looks good, too:
        </p>
        <ul>
          <li>So here is the first item in this list.</li>
          <li>In this example we're keeping the items short.</li>
          <li>Later, we'll use longer, more complex list items.</li>
        </ul>
        <p>And that's the end of this section.</p> */}

                <h2>Online Convenience with Prescription Refills</h2>
                <p>
                    In the era of digital convenience, managing your health should be effortless. With our online prescription refill
                    service, taking care of your medications is as simple as a few clicks. Log in, refill your prescriptions, and let us
                    handle the rest. It's quick, easy, and ensures you stay on top of your health
                </p>
                <h2>Wellness Essentials at Your Fingertips</h2>
                <p>
                    Explore our online store, a treasure trove of wellness essentials curated to support your overall well-being. From
                    vitamins and supplements to skincare and self-care products, discover a range of items designed to enhance your health
                    journey.
                </p>

                <h2>Health Guides and Resources</h2>
                <p>
                    Knowledge is a powerful tool for maintaining good health. Dive into our health guides and articles covering various
                    topics. Whether you seek insights into medications, lifestyle tips, or condition management advice, our blog serves as a
                    valuable resource to keep you informed.
                </p>
                <h2>Online Convenience with Prescription Refills</h2>
                <p>
                    In the era of digital convenience, managing your health should be effortless. With our online prescription refill
                    service, taking care of your medications is as simple as a few clicks. Log in, refill your prescriptions, and let us
                    handle the rest. It's quick, easy, and ensures you stay on top of your health
                </p>
                <h2>Your Questions Answered – Customer Support</h2>
                <p>
                    Have a question or need assistance? Our dedicated customer support team is ready to help. Contact us via phone, email,
                    or visit our store for personalized support. We're committed to providing the assistance you need on your health and
                    wellness journey.
                </p>
                <h2>Subscribe for Exclusive Offers and Updates</h2>
                <p>
                    Stay connected with Rite Away Pharmacy by subscribing to our newsletter. Receive exclusive offers, health tips, and the
                    latest updates directly to your inbox. Join our community of health enthusiasts and embark on a journey towards a
                    healthier, happier life.
                </p>
                <h2>Conclusion</h2>
                <p>
                    At Rite Away Pharmacy, we believe that your journey to wellness should be seamless and supported. From online
                    prescription refills to a wealth of health resources, we're here to empower you on your path to a healthier lifestyle.
                    Join us in embracing wellness – because your health matters, every step of the way.
                </p>
            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap space-x-2">
                <Tag />
                <Tag />
                <Tag />
                <Tag />
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">WRITEN BY</span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="##">Test</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="##">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5 ">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200 ">Responses (14)</h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3 mb-20">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (_: any, index: number) => {
        return (
            <div key={index} className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group">
                <Link to={'/blog-single'} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={_getImgRd()}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{_getTitleRd()}</span>
                    </h2>

                    <div className="flex">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">{_getPersonNameRd()}</span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">Jan 24 , 2024</span>
                    </div>
                </div>
                <Link to={'/blog-single'} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle">
            <Helmet>
                <title>Single Blog </title>
            </Helmet>
            <div className="page-title flex items-center justify-center  mb-20" style={{ backgroundColor: '#d6cdea' }}>
                <div className="content-box clearfix">
                    <div>
                        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-2">Embracing Wellness</h2>
                    </div>
                    <ul className="bread-crumb clearfix  flex items-center justify-center">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            {' '}
                            <a href="/blog">Blog</a>
                        </li>
                        <li> Embracing Wellness</li>
                    </ul>
                </div>
            </div>
            {renderHeader()}
            {/* <NcImage
        className="rounded-xl h-96"
        // containerClassName="absolute inset-0"
        containerClassName="container my-10 sm:my-12 "
        src={Blogimage}
      /> */}
            <div className="prose my-10  mx-auto ">
                <figure>
                    <img
                        src={Blogimage}
                        alt="nc blog"
                        className="rounded-2xl "
                        // style={{ height: "70ch" }}
                    />
                </figure>
            </div>
            <div className="nc-SingleContent container space-y-10 mb-20">
                {renderContent()}
                {/* {renderTags()} */}
                {/* <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div> */}
                {/* {renderAuthor()} */}
                {/* {renderCommentForm()} */}
                {/* {renderCommentLists()} */}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {[1, 1, 1, 1].filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div> */}
        </div>
    );
};

export default BlogSingle;
