import React, { useState } from 'react';
import NavigationItem from './NavigationItem';
import { NAVIGATION_DEMO_2 } from 'data/navigation';
import { MapPin, Phone } from 'react-feather';

function Navigation() {
    const googleMapsUrl = 'https://www.google.com/maps?q=2235+Thousand+Oaks+Dr+Ste+102+San+Antonio+TX+78232';
    const handleItemClick = (id: string) => {
        // console.log("Clicked on item with id:", id);
    };
    return (
        <>
            <ul className="nc-Navigation flex items-center">
                {NAVIGATION_DEMO_2.map((item) => (
                    <NavigationItem key={item.id} menuItem={item} itemClick={handleItemClick} />
                ))}
            </ul>
            {/* <div className="flex items-center ml-auto  ">
        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
          <MapPin size={15} className="inline-block mr-2" />
          <small> San Antonio, Texas</small>
        </a>
      </div> */}
        </>
    );
}

export default Navigation;
