import { StarIcon } from "@heroicons/react/24/solid";
import { productImgs } from "contains/fakeData";
import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import { string } from "yup";
import card from "../images/CARD.png";
import card2 from "../images/CARD2.png";
import Defaultimage from "../images/placeholder-small.png";
export interface CollectionCard2Props {
  className?: string;
  imgs?: string[];
  name?: string;
  price?: number;
  image?: string;
  description?: string;
  id: string;
}

const MetalCollections: FC<CollectionCard2Props> = ({
  className,
  imgs = [productImgs[9], productImgs[10], productImgs[11], productImgs[8]],
  name,
  image,
  id,
  description = "Product Description",
  price,
}) => {
  const url = "https://dev-api.instaacard.com/";
  const sliderimage = `${url}${image}`;

  const navigate = useNavigate();

  const clickProductDetail = () => {
    // const titleProps = product.name.split(' ').join('');
    navigate(`/product-details/${id}`);
    // console.log("id", data?.id);
  };
  return (
    <div
      className={`CollectionCard2 group relative ${className}`}
      style={{ cursor: "pointer" }}
      onClick={clickProductDetail}
    >
      <div className="relative flex flex-col">
        <NcImage
          containerClassName="aspect-w-8 aspect-h-5 bg-neutral-100 rounded-2xl overflow-hidden"
          className="object-fill w-full h-full rounded-2xl"
          src={sliderimage}
        />
        <div className="grid grid-cols-3 gap-2.5 mt-2.5">
          <NcImage
            containerClassName="w-full h-24 sm:h-28"
            className="object-contain w-full h-full rounded-2xl"
            src={Defaultimage}
          />
          <NcImage
            containerClassName="w-full h-24 sm:h-28"
            className="object-contain w-full h-full rounded-2xl"
            src={Defaultimage}
          />
          <NcImage
            containerClassName="w-full h-24 sm:h-28"
            className="object-cover w-full h-full rounded-2xl"
            src={Defaultimage}
          />
        </div>
      </div>

      <div className="relative mt-5 flex justify-between">
        {/* TITLE */}
        <div className="flex-1">
          <h2 className="font-semibold text-lg sm:text-xl ">{name}</h2>
        </div>
        <Prices className="mt-0.5 sm:mt-1 ml-4" price={price} />
      </div>
    </div>
  );
};

export default MetalCollections;
