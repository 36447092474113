import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name: string;
  desc: string;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
}) => {
  return (
    <div
      className={`CollectionCard2 group relative ${className}`}
      style={{ cursor: "pointer" }}
    >
      <div className="relative flex flex-col">
        <NcImage
          containerClassName="aspect-w-8 aspect-h-5 bg-neutral-100 rounded-2xl overflow-hidden"
          src={featuredImage}
          className="object-conatin rounded-2xl"
        />
      </div>

      <span className="opacity-0  absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>

      <div className="mt-5 flex-1 text-center">
        {/* <h2 className="text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2> */}
        <span className="text-base font-semibold">{desc}</span>
      </div>
    </div>
  );
};

export default CardCategory2;
