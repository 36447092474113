import React, { FC } from 'react';

import hippa from '../../pdf/Hippa.pdf';
export interface PageSubcriptionProps {
    className?: string;
}

const PageSubcription: FC<PageSubcriptionProps> = ({ className = '' }) => {
    return (
        <div className={`pdf-container ${className}`}>
            <embed type="application/pdf" src="https://riteawaypharmacy-landing.teamfeeltech.com/Hippa.pdf" className="pdf-embed" />
        </div>
    );
};

export default PageSubcription;
