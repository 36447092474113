import React, { FC, useEffect, useId } from 'react';
import Heading from 'components/Heading/Heading';
import Glide from '@glidejs/glide';
import CardCategory2 from 'components/CardCategories/CardCategory2';
import serviceimage from '../../images/pharmacy_image/refill.png';
import serviceimage1 from '../../images/pharmacy_image/Featureimage/pexels-artem-podrez-4492048.jpg';
import serviceimage2 from '../../images/pharmacy_image/Featureimage/pexels-artem-podrez-4492069.jpg';
import serviceimage3 from '../../images/pharmacy_image/Featureimage/pexels-ivan-samkov-4989138.jpg';
import serviceimage4 from '../../images/pharmacy_image/Featureimage/pexels-mart-production-7230192.jpg';
import serviceimage5 from '../../images/pharmacy_image/Featureimage/paisa.png';
import { Link } from 'react-router-dom';
import SectionStatistic from 'containers/PageAbout/SectionStatistic';

export interface CardCategoryData {
    name: string;
    desc: string;
    img: string;
    color?: string;
}

export interface SectionSliderCategoriesProps {
    className?: string;
    itemClassName?: string;
    heading?: string;
    subHeading?: string;
    data?: CardCategoryData[];
}
export interface CardCategoryData {
    name: string;
    desc: string;
    img: string;
    color?: string;
}

const CATS: CardCategoryData[] = [
    {
        name: 'Refills',
        desc: 'Refill prescriptions online',
        img: serviceimage,
        color: 'bg-indigo-100',
    },
    {
        name: 'Refills',
        desc: 'Retail Service',
        img: serviceimage1,
        color: 'bg-indigo-100',
    },
    {
        name: 'Refills',
        desc: 'Sterile/Non Sterile Compounding',
        img: serviceimage2,
        color: 'bg-indigo-100',
    },
    {
        name: 'Refills',
        desc: 'Long Term Care Facilities',
        img: serviceimage3,
        color: 'bg-indigo-100',
    },
    {
        name: 'Refills',
        desc: 'Personalized Service – white glove, synchronization',
        img: serviceimage4,
        color: 'bg-indigo-100',
    },
    {
        name: 'Refills',
        desc: 'Specialty Pharmacy Discount Programs',
        img: serviceimage5,
        color: 'bg-indigo-100',
    },
];

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
    heading = 'Featured categories',
    subHeading = '',
    className = '',
    itemClassName = '',
    data = CATS,
}) => {
    return (
        <>
            <div className="page-title flex items-center justify-center" style={{ backgroundColor: '#d6cdea' }}>
                <div className="content-box clearfix">
                    <h1>Our Services</h1>
                    <ul className="bread-crumb clearfix  flex items-center justify-center">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>Our Services</li>
                    </ul>
                </div>
            </div>
            <div className="container relative  my-20 lg:space-y-32 lg:my-20">
                <div>
                    {/* <div className="flex  justify-center items-center ">
                        <h3 className="text-3xl">Our Pharmacy Services</h3>
                    </div> */}
                    {/* <div className="flex  justify-center items-center">
                        <span>Services we provide</span>
                    </div> */}

                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-10">
                        {CATS.map((item, index) => (
                            <CardCategory2 featuredImage={item.img} name={item.name} desc={item.desc} bgClass={item.color} />
                        ))}
                    </div>

                    <div className="my-20 sm:my:18">
                        <SectionStatistic />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SectionSliderCategories;
