// ** Reducers Imports
import auth from './authslice'
import crudApis from './crud';


const rootReducer = {
    auth,
    ...crudApis
};

export default rootReducer;
