import CardCategory1 from "components/CardCategories/CardCategory1";
import CardCategory4 from "components/CardCategories/CardCategory4";
import CardCategorynew from "components/CardCategories/CardCategorynew";
import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import React, { FC, useEffect, useState } from "react";
import Nav from "shared/Nav/Nav";
import axios from "../../service/axios";

import explore1Svg from "images/collections/explore1.svg";
import explore2Svg from "images/collections/explore2.svg";
import explore3Svg from "images/collections/explore3.svg";
import explore4Svg from "images/collections/explore4.svg";
import explore5Svg from "images/collections/explore5.svg";
import explore6Svg from "images/collections/explore6.svg";
import explore7Svg from "images/collections/explore7.svg";
import explore8Svg from "images/collections/explore8.svg";
import explore9Svg from "images/collections/explore9.svg";
//
import explore1Png from "images/collections/explore1.png";
import explore2Png from "images/collections/explore2.png";
import explore3Png from "images/collections/explore3.png";
import explore4Png from "images/collections/explore4.png";
import explore5Png from "images/collections/explore5.png";
import explore6Png from "images/collections/explore6.png";
import explore7Png from "images/collections/explore7.png";
import explore8Png from "images/collections/explore8.png";
import explore9Png from "images/collections/explore9.png";
import CardCategory6 from "components/CardCategories/CardCategory6";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  getProduct,
  getProductcategory,
} from "../../redux/store/products/index";
import { Product, ProductItem } from "redux/store/products/class";
import PulseAnimation from "../Loader/ProductCategories";

interface ExploreType {
  // id: string;
  name: string;
  desc: string;
  image: string;
  svgBg: string;
  color?: string;
}

export interface SectionGridMoreExploreProps {
  className?: string;
  gridClassName?: string;
  boxCard?: "box1" | "box4" | "box6";
  data?: ExploreType[];
}

export const DEMO_MORE_EXPLORE_DATA = [
  {
    id: 1,
    name: "Backpack",
    desc: "Manufacturar",
    image: explore1Png,
    svgBg: explore1Svg,
    color: "bg-indigo-50",
  },
  {
    id: 2,
    name: "Shoes",
    desc: "Manufacturar",
    image: explore2Png,
    svgBg: explore2Svg,
    color: "bg-slate-100/80",
  },
  {
    id: 3,
    name: "Recycled Blanket",
    desc: "Manufacturar",
    image: explore3Png,
    svgBg: explore3Svg,
    color: "bg-violet-50",
  },
  {
    id: 4,
    name: "Cycling Shorts",
    desc: "Manufacturar",
    image: explore9Png,
    svgBg: explore9Svg,
    color: "bg-orange-50",
  },
  {
    id: 5,
    name: "Cycling Jersey",
    desc: "Manufacturar",
    image: explore5Png,
    svgBg: explore5Svg,
    color: "bg-blue-50",
  },
  {
    id: 6,
    name: "Car Coat",
    desc: "Manufacturar",
    image: explore6Png,
    svgBg: explore6Svg,
    color: "bg-orange-50",
  },
  {
    id: 7,
    name: "Sunglasses",
    desc: "Manufacturar",
    image: explore7Png,
    svgBg: explore7Svg,
    color: "bg-stone-100",
  },
  {
    id: 8,
    name: "kid hats",
    desc: "Manufacturar",
    image: explore8Png,
    svgBg: explore8Svg,
    color: "bg-blue-50",
  },
  {
    id: 9,
    name: "Wool Jacket",
    desc: "Manufacturar",
    image: explore4Png,
    svgBg: explore4Svg,
    color: "bg-slate-100/80",
  },
];

const SectionGridMoreExplore: FC<SectionGridMoreExploreProps> = ({
  className = "",
  boxCard = "box4",
  gridClassName = "grid-cols-1 md:grid-cols-2 xl:grid-cols-3",
  // data = DEMO_MORE_EXPLORE_DATA.filter((_, i) => i < 3),
}) => {
  const ProductList: Product[] = useSelector(
    (state: RootState) => state?.productSlice.product
  );
  const productCategory: ProductItem[] = useSelector(
    (state: RootState) => state?.productSlice?.productcategory
  );
  // const [tabActive, setTabActive] = React.useState("Man");
  const dispatch = useDispatch<AppDispatch>();

  const getProductcategoryOption = async () => {
    // setLoading(true);
    await dispatch(getProductcategory());
    // setLoading(false);
  };

  const [loading, setLoading] = useState(true);
  const [tabActive, setTabActive] = React.useState("");

  useEffect(() => {
    getProductcategoryOption();
  }, []);

  const renderCard1 = (item: Product) => {
    switch (boxCard) {
      case "box4":
        return (
          <CardCategorynew
            name={item.name}
            id={item.id}
            image={item.ProductImageMappings[0]?.ProductImage?.path}
          />
        );
    }
  };
  const handleTabRender = async (value: string) => {
    // setTabActive(value);
    const query = `productCategoryId=${value}`;
    setLoading(true);
    await dispatch(getProduct(query))
      .unwrap()
      .catch((err) => {});
    setLoading(false);
  };
  useEffect(() => {
    if (productCategory.length > 0) {
      setTabActive(productCategory[0]?.label);
      // setLoading(true);
      handleTabRender(productCategory[0]?.value);
      // setLoading(false);
    }
  }, [productCategory]);
  const renderHeading = () => {
    return (
      <div>
        <Heading
          className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
          isCenter
          desc=""
        >
          Start exploring.
        </Heading>
        <Nav
          className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
          containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
        >
          {productCategory.map((item, index) => (
            <NavItem2
              key={index}
              isActive={tabActive === item?.label}
              onClick={() => {
                setTabActive(item?.label);
                handleTabRender(item.value);
              }}
            >
              <div className="flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm ">
                <span className="inline-block"></span>
                <span>{item.label}</span>
              </div>
            </NavItem2>
          ))}
        </Nav>
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionGridMoreExplore relative ${className}`}
      data-nc-id="SectionGridMoreExplore"
    >
      {renderHeading()}
      {loading && (
        <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
          {Array.from({ length: 6 }).map((_, index) => (
            <div key={index}>
              <PulseAnimation />
            </div>
          ))}
        </div>
      )}
      {!loading && productCategory && productCategory.length > 0 && (
        <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
          {ProductList.map((item, index) => renderCard1(item))}
        </div>
      )}
    </div>
  );
};

export default SectionGridMoreExplore;
