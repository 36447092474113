import Logo from 'shared/Logo/Logo';
import SocialsList1 from 'shared/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import React from 'react';
import pharmacyLogo from '../../images/logo/transpraentlogo.png';

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
    {
        id: '5',
        title: 'Getting started',
        menus: [
            { href: '/', label: 'Home' },
            { href: '/about', label: 'About Us' },
            { href: '/contact', label: 'Contact Us' },
            { href: '/states-serviced', label: 'States Serviced' },
            { href: '/delivery-service', label: 'Delivery Policy' },
        ],
    },
    {
        id: '1',
        title: 'Explore',
        menus: [
            { href: 'https://riteaway1.drxrefill.com/store', label: 'Shop' },
            { href: '/service', label: 'Services' },
            {
                href: '/hippa-service',
                label: 'HIPAA Policy',
                // onClick: () => {
                //     const fileUrl = process.env.PUBLIC_URL + '/Hippa.pdf';
                //     window.open(fileUrl, '_blank');
                // },
            },
        ],
    },
    {
        id: '2',
        title: 'Phone',
        menus: [
            { href: '#', label: '📞   (210) 490-2733 ' },
            { href: '#', label: '🖨  (210) 490-2416 ' },
            { href: '#', label: '' },
            { href: '#', label: '' },
        ],
    },
    {
        id: '4',
        title: 'Store Hours',
        menus: [
            { href: '#', label: 'Mon - Fri  9:00 AM - 7:00 PM ' },
            { href: '#', label: 'Sat  10:00 AM - 3:00 PM ' },
            { href: '#', label: 'Sun  Closed ' },
            { href: '#', label: '' },
        ],
    },
];
const Footer: React.FC = () => {
    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index}>
                <h2 className="font-bold text-md text-neutral-700 dark:text-neutral-200">{menu.title}</h2>
                <ul className="mt-5 space-y-4 text-sm">
                    {menu.menus.map((item, index) => (
                        <li key={index}>
                            {/* <a
                                key={index}
                                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                href={item.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.label}
                            </a> */}

                            <a
                                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                href={item.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                dangerouslySetInnerHTML={{ __html: item.label }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <>
            <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700 bg-blue-50">
                <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
                    <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                        <div className="col-span-2 md:col-span-1">
                            <img src={pharmacyLogo} alt="Logo" style={{ width: '80%' }} />
                        </div>
                        <div className="col-span-2 flex items-center md:col-span-3">
                            <div>Your local independent pharmacy</div>
                            {/* <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" /> */}
                        </div>
                    </div>
                    {widgetMenus.map(renderWidgetMenuItem)}
                </div>
            </div>

            <div className="lg:pt-10 lg:pb-10 border-t border-neutral-200 dark:border-neutral-700 flex items-center justify-center">
                Rite Away Pharmacy © 2024 All Rights Reserved.
            </div>
        </>
    );
};

export default Footer;
