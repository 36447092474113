import React, { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import BestSellarsSlider from "./BestSellarsSlider";
import { PRODUCTS } from "data/data";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getBestSellerProduct, getSliderProduct } from "./store/products";
import { Product } from "redux/store/products/class";
import PulseAnimation from "./Loader/metal"; // Import or create your loader component

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  // data?: Product[];
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "REY backpacks & bags",
  // data = PRODUCTS.filter((_, i) => i < 8 && i > 2),
}) => {
  const sliderProduct: Product[] = useSelector(
    (state: RootState) => state?.productSliderSlice.bestproductslider
  );
  const [isLoading, setIsLoading] = useState(true);

  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  // console.log(data, "daat");

  useEffect(() => {
    if (!sliderRef.current) {
      return () => {};
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS, isLoading]);
  const dispatch = useDispatch<AppDispatch>();

  const SliderProduct = async () => {
    // handleDxLoader(true);
    setIsLoading(true);

    await dispatch(getBestSellerProduct())
      .unwrap()
      .catch((err) => {});
    setIsLoading(false);
    // handleDxLoader(false);
  };
  useEffect(() => {
    SliderProduct();
  }, []);
  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || `New Arrivals`}
        </Heading>
        {isLoading && (
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {/* {sliderProduct.map((item, index) => ( */}

              <div>
                <PulseAnimation></PulseAnimation>
              </div>
              <div>
                <PulseAnimation></PulseAnimation>
              </div>
              <div>
                <PulseAnimation></PulseAnimation>
              </div>
              <div>
                <PulseAnimation></PulseAnimation>
              </div>

              {/* ))} */}
            </ul>
          </div>
        )}
        {!isLoading && (
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {sliderProduct.map((item, index) => (
                <li key={index} className={`glide__slide ${itemClassName}`}>
                  <BestSellarsSlider
                    id={item.id}
                    name={item.name}
                    price={item.price}
                    image={item.ProductImageMappings[0]?.ProductImage?.path}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionSliderProductCard;
