// import rightImg from 'images/hero-right1.png';
import store1 from "images/pharmacy_image/TeamMembers/cropimage.png";
import store2 from "images/pharmacy_image/TeamMembers/cropimage2.png";

import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import SectionHero2 from "./SectionHero2";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About RiteAway Pharmacy</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div
        className="page-title flex items-center justify-center"
        style={{ backgroundColor: "#d6cdea" }}
      >
        <div className="content-box clearfix">
          <h1>About us</h1>
          <ul className="bread-crumb clearfix  flex items-center justify-center">
            <li>
              <a href="/">Home</a>
            </li>

            <li>About us</li>
          </ul>
        </div>
      </div>

      <div className="container space-y-16 lg:space-y-16">
        {/* <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    About us
                </h2> */}

        <SectionHero
          rightImg={store1}
          heading="Our Journey"
          // heading="👋 About Us."
          btnText=""
          subHeading="Rite Away Pharmacy traces its roots back to 2003 when a group of dedicated individuals embarked on a journey in the realm of chain retail pharmacies. Recognizing the limitations of the chain retail model in providing comprehensive services tailored to the unique needs of their community, the team was driven by a heartfelt commitment to better serve their customers. In 2010, this vision materialized with the opening of the first Rite Away Pharmacy store. The response was overwhelmingly positive, and the pharmacy has experienced consistent growth since its inception."
        />

        <SectionHero2
          heading="Our Growth"
          btnText=""
          subHeading="In 2023, the Rite Away brand boasts a total of 6 locations across San Antonio & Austin. Rite Away Pharmacy has positioned itself as a one-stop-shop for all medication needs, offering a wide range of services including sterile and non-sterile compounding, prescription items, specialty medications, over-the-counter products, medical supplies, vaccinations, and CLIA waived diagnostic testing. The pharmacy's history is a testament to its unwavering commitment to providing comprehensive and personalized healthcare solutions to the communities it serves."
          rightImg={store2}
        />
        <SectionFounder />
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}
        <div>
          <SectionStatistic className="mb-20" />
        </div>

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
