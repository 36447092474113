import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/promo3.png";
import backgroundLineSvg from "images/BackgroundLine.svg";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionPromo3Props {
  className?: string;
}

const SectionPromo3: FC<SectionPromo3Props> = ({ className = "lg:pt-10" }) => {
  return (
    <div className={`nc-SectionPromo2 ${className}`}>
      <div className="rounded-tl-3xl rounded-tr-3xl relative flex flex-col lg:flex-row  bg-orange-100 dark:bg-slate-800  p-4 pb-0 sm:p-5 sm:pb-0 lg:p-24 mt-24">
        <div className="absolute inset-0">
          <img
            className="absolute w-full h-full object-contain dark:opacity-5"
            src={backgroundLineSvg}
            alt="backgroundLineSvg"
          />
        </div>
        <div className="lg:w-[45%] max-w-lg relative">
          {/* <Logo className="w-28" /> */}
          <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl 2xl:text-6xl mt-6 sm:mt-10 ">
            Save on Medicare Rx
          </h2>
          {/* <br />
          <br /> */}
          <span className="block mt-6 text-slate-500 dark:text-slate-400 ">
            Did you know? RiteWay is a Preferred Pharmacy with many Medicare
            plans
          </span>
          <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
            {/* <ButtonPrimary href="/page-search" className="bg-red-700">
              Learn More
            </ButtonPrimary> */}
          </div>
        </div>

        <NcImage
          containerClassName="relative block lg:absolute lg:right-0 lg:bottom-0 mt-10 lg:mt-0 max-w-lg lg:max-w-[calc(50%-40px)]"
          src={rightImgDemo}
        />
      </div>
      <div>
        <div className="bg-orange-100 dark:bg-neutral-800 rounded-b-3xl ">
          <div className="flex items-center justify-evenly   space-x-1.5 sm:space-x-2.5 leading-10 p-2">
            {/* <span className="inline-block"></span>
            <span className="text-base font-semibold">Lorium Ipsum</span>
            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
            <span className="text-base font-semibold">Lorium Ipsum</span>
            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
            <span className="text-base font-semibold">Lorium Ipsum</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionPromo3;
