import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/promo2.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import backgroundLineSvg from "images/Moon.svg";
import Nav from "shared/Nav/Nav";
import NavItem2 from "components/NavItem2";

export interface SectionPromo2Props {
  className?: string;
}

const SectionPromo2: FC<SectionPromo2Props> = ({ className = "lg:pt-10" }) => {
  return (
    <div className={`nc-SectionPromo2 ${className}`}>
      <div className="rounded-tl-3xl rounded-tr-3xl relative flex flex-col lg:flex-row lg:justify-end bg-yellow-50 dark:bg-slate-800  p-4 pb-0 sm:p-5 sm:pb-0 lg:p-24 mt-28">
        <div className="absolute inset-0">
          <img
            className="absolute w-full h-full object-contain dark:opacity-5"
            src={backgroundLineSvg}
            alt="backgroundLineSvg"
          />
        </div>
        <div className="lg:w-[45%] max-w-lg relative ">
          {/* <Logo className="w-28" /> */}
          <span className="font-serif font-normal text-gray-800 text-4xl">
            40% off Photo Gifts
          </span>
          <br />
          <br />
          {/* <br /> */}
          <span className="font-serif font-normal text-gray-800 text-xl">
            Send warm memories their way
          </span>

          <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
            <ButtonPrimary href="/page-search" className="bg-red-700">
              Create New
            </ButtonPrimary>
          </div>
        </div>

        <NcImage
          containerClassName="relative block lg:absolute lg:left-0 lg:bottom-0 mt-10 lg:mt-0 max-w-xl lg:max-w-[calc(55%-40px)]"
          src={rightImgDemo}
        />
      </div>
      <div>
        <div className="bg-yellow-50 dark:bg-neutral-800 rounded-b-3xl ">
          <div className="flex items-center justify-evenly   space-x-1.5 sm:space-x-2.5 leading-10 p-2">
            <span className="inline-block"></span>
            <span className="text-base font-semibold">15¢ 4x6 Prints 75+</span>
            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
            <span className="text-base font-semibold">
              50% off Enlargements & Posters
            </span>
            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
            <span className="text-base font-semibold">50% off Wall Décor</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionPromo2;
